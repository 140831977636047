import { css, px2grid, px2rem, mediaQuery, ColorBrandBase } from '@ampli/ui';

export const logoHorizontal = css`
  align-self: center;
  box-sizing: content-box;
  display: block;
  height: auto;
  margin: 0 auto;
  width: ${px2grid(100)};
  ${mediaQuery('desktop')} {
    margin: 0;
  }
`;
export const footerStyle = css`
  background: ${ColorBrandBase};
  display: flex;
  width: 100%;
  color: white;
  padding-top: ${px2grid(56)};
  position: relative;
`;

export const logoListStyle = css`
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style: none;
  margin-bottom: ${px2grid(24)};
  padding: 0;
  text-align: center;

  ${mediaQuery('desktop')} {
    margin-bottom: 0;
    text-align: left;
    flex-direction: row;
    margin-left: ${px2grid(75)};
  }
`;

export const socialListStyle = css`
  list-style: none;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 95%;
`;

export const storesListStyle = css`
  padding: ${px2grid(8)} 0;
  text-align: center;
  display: flex;
  margin-bottom: 0;
`;

export const wrapStyle = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  ${mediaQuery('tablet')} {
    flex-direction: row;
  }
`;

export const qrCodeContainer = css`
  background: rgba(0, 102, 107, 0.4);
  border-radius: 30px;
  width: ${px2grid(360)};
  padding: ${px2grid(24)};
  display: flex;
  flex-direction: row;
  margin-left: ${px2grid(100)};
  margin-bottom: ${px2grid(16)};
  align-items: center;

  ${mediaQuery('mobile')} {
    margin-left: 0px;
  }
`;

export const socialItemStyle = css`
  display: inline-block;
  padding: 0;

  a {
    width: 26px;
    height: 26px;
    display: block;
  }
`;

export const mediaIconStyle = css`
  box-sizing: content-box;
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const mediaSectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${px2grid(16)};
  align-items: center;
  width: 100%;

  ${mediaQuery('desktop')} {
    flex-direction: row;
    padding: ${px2grid(12)};
  }
`;

export const footerSeparatorStyle = css`
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  height: ${px2rem(1)};
  margin: ${px2grid(1)} 0;
  padding: 0;
  width: 100%;

  ${mediaQuery('desktop')} {
    display: none;
  }
`;

export const socialWrapperStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;

  ${mediaQuery('mobile')} {
    align-items: center !important;
  }
`;

export const securityItemStyle = css`
  display: block;
  padding: ${px2grid(20)};
  margin: 0 ${px2grid(14)};
`;

export const storesItemStyle = css`
  display: inline-block;
  padding: 0;
`;

export const logoSeparatorStyle = css`
  height: ${px2grid(48)};
  visibility: visible;
`;

export const logoItemStyle = (isDesktop: boolean) => css`
  ${!isDesktop && 'display: none'}
`;

export const qrCodeImageContainer = css`
  background: #ffffff;
  border-radius: 8px;
  width: ${px2grid(400)};
  padding: ${px2grid(4)};
  align-items: center;
  margin-left: 16px;
  display: flex;
`;

export const mediaSection = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${mediaQuery('mobile')} {
    flex-direction: column;
    align-items: center;
  }
`;

export const storesLinkStyle = css`
  margin-right: ${px2grid(12)};
`;

export const footerSeparatorDesktopStyle = css`
  ${mediaQuery('desktop')} {
    width: 100vw;
    display: block;
  }
`;

export const footerSection = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${px2grid(14)};
  max-width: ${px2grid(680)};
  width: 100%;

  ${mediaQuery('tablet')} {
    max-width: calc(100% - ${px2grid(80)} * 2);
  }

  ${mediaQuery('desktop')} {
    padding: 0;
  }
`;
