import { css, deskMin, px2rem, getColor, mediaQuery } from '@ampli/ui';

export default {
  button: css`
    position: fixed;
    z-index: 10;
    left: ${px2rem(8)};
    bottom: ${px2rem(16)};
    margin-bottom: ${px2rem(5)};
    @media (min-width: ${deskMin}) {
      right: ${px2rem(16)};
    }
    ${mediaQuery('mobile')} {
      bottom: ${px2rem(8)};
    }
    padding: ${px2rem(12)} !important;
    background: ${getColor('anhanguera-neutral-background')};
    font-weight: 500;
    border-radius: 12px;
    font-size: ${px2rem(16)};
    color: black;
    box-shadow: 0px 8px 16px ${getColor('anhanguera-shadow')};
  `,
};
