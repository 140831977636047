import React, { useState, useEffect } from 'react';
import { string } from '@ampli/utils';
import styles from './styles';
import { cx } from '@ampli/ui';

const BannerHeader = () => {
  const [visible, setVisibility] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={cx(
        'alert-banner',
        visible && scrollTop <= 0 ? styles.alertBanner : styles.alertBannerOff
      )}
    >
      <div className={cx('alert-banner-content', styles.alertBannerContent)}>
        <a
          className={cx('alert-banner-text', styles.alertBannerText)}
          href="https://www.anhanguera.com/"
          target="_blank"
          rel="noreferrer"
        >
          Ampli e Anhanguera estão juntas!
        </a>
        <button
          onClick={() => setVisibility(false)}
          className={cx('alert-banner-button', styles.alertBannerButton)}
        />
      </div>
    </div>
  );
};

BannerHeader.propTypes = {
  className: string,
};

export default BannerHeader;
