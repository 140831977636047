import { STORAGE_SUBSCRIPTION_FORM } from '../constants';

export const updateSubscriptionCache = (currentStep, data) => {
  const subscriptionForm =
    JSON.parse(localStorage.getItem(STORAGE_SUBSCRIPTION_FORM)) || {};
  const newSubscriptionForm = {
    ...subscriptionForm,
    ...(currentStep === 0 && { firstStepData: data }),
    ...(currentStep === 1 && { secondStepData: data }),
    ...(currentStep === 2 && { thirdStepData: data }),
  };
  localStorage.setItem(
    STORAGE_SUBSCRIPTION_FORM,
    JSON.stringify(newSubscriptionForm)
  );
};

export const generateRegistrationStepNumber = (currentStep) => {
  if (currentStep) {
    const stepNumberAsString = `1.${currentStep + 1}`;
    return parseFloat(stepNumberAsString);
  }
};

export const saveCourseDetailToSubscribe = (course, degreeType) => {
  const courseDetail = {
    course: course?.urlCode,
    courseDegree: degreeType,
    courseId: course?.id,
    coursePrice: course?.price,
  };
  typeof window !== 'undefined' &&
    sessionStorage.setItem(
      'selectedCourseToSubscribe',
      JSON.stringify(courseDetail)
    );
};
