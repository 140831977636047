import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ApolloClient, ApolloLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import { APIError, authStorage, compact, concat, forEach, GraphQLError, omitDeep } from '@ampli/utils';
import typePolicies from './type-policies';
import { createCss, showError } from './error/default-error-notifier';
export var logout = function logout() {
  return authStorage.clear();
};

var checkUnauthorizedStatus = function checkUnauthorizedStatus(status) {
  return status === 401 || status === 403;
};

var cacheCreator = function cacheCreator() {
  var cacheSettings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new InMemoryCache(_objectSpread({
    typePolicies: typePolicies
  }, cacheSettings)).restore(window.__APOLLO_STATE__ || {});
};

var omitTypenameLink = new ApolloLink(function (operation, forward) {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, ['__typename']);
  }

  return forward(operation);
});
export var errorLink = onError(function (_ref) {
  var graphQLErrors = _ref.graphQLErrors,
      operation = _ref.operation,
      networkError = _ref.networkError;
  var context = operation.getContext();
  var requestHeaders = context.headers || {};
  var response = context.response || {};
  var responseStatus = response.status;
  var operationName = operation.operationName;
  var autoHandle = context.autoHandle;

  var logoutCallback = function logoutCallback() {
    window.history.replaceState(null, '', '/');
  };

  var dispatchError = function dispatchError(message, status, ErrorClass) {
    if (checkUnauthorizedStatus(status) && !context.skipLogout) {
      var _requestHeaders$autho;

      if (((_requestHeaders$autho = requestHeaders.authorization) === null || _requestHeaders$autho === void 0 ? void 0 : _requestHeaders$autho.indexOf('Bearer')) >= 0) {
        logout().then(logoutCallback).catch(console.error);
      }
    } else {
      console.error(new ErrorClass(message, {
        requestHeaders: requestHeaders,
        responseStatus: responseStatus,
        operationName: operationName
      }));
    }
  };

  if (graphQLErrors) {
    forEach(graphQLErrors, function (error) {
      if (autoHandle) {
        if (error.status === 406) {
          showError(error.message);
        } else {
          console.error(new GraphQLError(error.message, {
            requestHeaders: requestHeaders,
            responseStatus: responseStatus,
            operationName: operationName
          }));
          showError('Tivemos um erro inesperado, por favor tente novamente.');
        }
      } else {
        dispatchError("".concat(error.code, ": ").concat(error.message, " (").concat(error.status, ")"), error.status, GraphQLError);
      }
    });
  }

  if (networkError) {
    dispatchError(networkError.message, responseStatus, APIError);
  }
});
var headersLink = setContext(function (_ref2) {
  var _ref2$headers = _ref2.headers,
      headers = _ref2$headers === void 0 ? {} : _ref2$headers;
  return {
    headers: _objectSpread({
      authorization: 'Basic cG9ydGFsOnNlY3JldA=='
    }, headers)
  };
});
var authLink = setContext(function (_ref3) {
  var _ref3$headers = _ref3.headers,
      headers = _ref3$headers === void 0 ? {} : _ref3$headers;
  return authStorage.getItem('token').then(function (token) {
    return token ? {
      headers: _objectSpread(_objectSpread({}, headers), {}, {
        authorization: "Bearer ".concat(token)
      })
    } : {
      headers: headers
    };
  }).catch(function (error) {
    console.error(error);
    return {
      headers: headers
    };
  });
});
export var clientCreator = function clientCreator(_ref4) {
  var graphqlUrl = _ref4.graphqlUrl,
      _ref4$links = _ref4.links,
      links = _ref4$links === void 0 ? [] : _ref4$links,
      _ref4$secure = _ref4.secure,
      secure = _ref4$secure === void 0 ? true : _ref4$secure,
      _ref4$cacheSettings = _ref4.cacheSettings,
      cacheSettings = _ref4$cacheSettings === void 0 ? {} : _ref4$cacheSettings;
  createCss();
  var link = from(compact(concat(links, [errorLink, headersLink, secure && authLink, omitTypenameLink, createUploadLink({
    uri: graphqlUrl
  })])));
  return new ApolloClient({
    cache: cacheCreator(cacheSettings),
    link: link,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all'
      },
      query: {
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  });
};