import {
  css,
  getSpaceSize,
  mediaQuery,
  px2grid,
  px2rem,
  GradientLinearWarning,
} from '@ampli/ui';

export const bannerContainer = css`
  margin-bottom: ${px2grid(50)};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 2000px) {
    margin-bottom: 15%;
  }
`;

export const bannerHomeImage = css`
  width: 100%;
  background-size: cover;
  position: absolute;
  object-fit: cover;
  ${mediaQuery('mobile')} {
    background-position: 50% 0%;
    background-size: cover;
    margin-top: 0px;
  }
  ${mediaQuery('tablet')} {
    margin-top: 0px;
  }
  @media (min-width: 1400px) {
    margin-top: ${px2grid(50)};
  }
  @media (min-width: 2000px) {
    margin-top: ${px2grid(150)};
  }
`;

export const bannerHomeContent = css`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - ${px2rem(64)});
  min-height: ${px2grid(540)};

  ${mediaQuery('tablet')} {
    min-height: ${px2grid(700)};
  }

  ${mediaQuery('desktop')} {
    margin-top: 10%;
    min-height: auto;
    flex-direction: row;
    justify-content: center;
    height: calc(100vh - ${px2rem(90)});
    max-height: ${px2grid(690)};
  }
  @media (min-width: 1400px) {
    margin-top: ${px2grid(100)};
  }
  @media (min-width: 2000px) {
    margin-top: ${px2grid(200)};
  }
`;

export const bannerTitle = (isBlackWeek: boolean | undefined) => css`
  ${isBlackWeek
    ? `text-align: center;
    font-size: ${px2grid(80)};
    line-height: 1;
    `
    : `font-size: ${px2grid(144)}; 
  line-height: 1;`}

  ${mediaQuery('mobile')} {
    font-size: 4rem;
  }
`;

export const bannerDescriptionWrap = (isBlackWeek: boolean | undefined) => css`
  display: flex;
  flex-direction: column;
  ${mediaQuery('mobile')} {
    margin-bottom: 24%;
  }
  ${isBlackWeek
    ? ` 
  margin-bottom: ${getSpaceSize('xx-large')};
  margin-top: ${px2grid(40)};

  ${mediaQuery('mobile')} {
    margin-top: 0;
  }`
    : `
  margin-bottom: ${px2grid(50)};
  ${mediaQuery('mobile')} {
    margin-bottom: 24%;
  }`}
`;

export const bannerHomeText = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  ${mediaQuery('mobile')} {
    flex: 1;
    justify-content: flex-end;
  }

  ${mediaQuery('desktop')} {
    margin-top: -100px;
  }
`;

export const bannerBlackWeekSubtitle = css`
  text-align: center;
  font-size: ${getSpaceSize('large')};

  ${mediaQuery('mobile')} {
    font-size: ${getSpaceSize('medium')};
  }
`;

export const bannerCenterTextWrap = css`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  ${mediaQuery('tablet-min')} {
    margin-left: 6%;
  }
`;

export const bannerCenterText = css`
  margin-bottom: ${getSpaceSize('x-small')};
  ${mediaQuery('mobile')} {
    margin: ${getSpaceSize('small')} 0;
    margin-left: ${px2grid(50)};
  }
`;

export const bannerHomeFooter = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  ${mediaQuery('mobile')} {
    padding: 0;
    flex: 0;
  }
`;

export const bannerButton = css`
  margin-top: ${getSpaceSize('small')};
  background: ${GradientLinearWarning};
`;

export const doubleChevronIcon = css`
  margin-top: ${px2grid(20)};
  height: ${px2grid(30)};
  width: ${px2grid(20)};
`;

export const mecBadgeWrap = (isBlackWeek: boolean | undefined) => css`
  align-self: flex-end;
  display: flex;
  justify-content: flex-start;
  padding-top: ${px2grid(100)};
  margin-right: -200px;
  ${mediaQuery('desktop')} {
    margin-top: 3%;
    flex: 1;
    align-self: flex-start;
    justify-content: flex-end;
    max-width: ${px2grid(285)};
  }
  ${mediaQuery('mobile')} {
    ${isBlackWeek && 'display: none !important;'}
    position: absolute;
    top: 0;
  }
`;

export const mecBadgeMobile = css`
  width: ${px2grid(135)};
  height: ${px2grid(150)};
  z-index: 1;
`;

export const mecBadgeDesktop = css`
  width: ${px2grid(135)};
  height: ${px2grid(180)};
  z-index: 1;
  position: absolute;
`;
