import React from 'react';

interface SvgAnhangueraLogoProps {
  width?: string;
  height?: string;
}

const SvgAnhangueraLogo: React.FC<SvgAnhangueraLogoProps> = ({
  width = '33',
  height = '31',
  ...props
}: SvgAnhangueraLogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.94391 18.8066C7.54488 19.3125 6.18372 19.9176 4.87077 20.6172C4.51147 20.8235 4.21011 21.1172 3.99466 21.471L1.86432 25.3374C1.69525 25.6448 1.83204 25.8078 2.17173 25.7009C2.17173 25.7009 3.02632 25.4266 3.85632 25.1845C5.87138 24.5927 9.59639 23.7159 9.59639 23.7159C9.98311 23.6029 10.3149 23.3519 10.5286 23.0104L13.462 17.6853C13.6311 17.3779 13.4951 17.2165 13.1592 17.3256C13.1531 17.3256 11.065 17.9965 8.94391 18.8066Z"
        fill="#CD4C0A"
      />
      <path
        d="M27.8594 21.0991C27.9322 21.1033 28.0047 21.0867 28.0684 21.0513C28.1322 21.0158 28.1845 20.963 28.2193 20.8989C28.2541 20.8349 28.27 20.7622 28.265 20.6895C28.2601 20.6167 28.2346 20.5469 28.1914 20.4881L25.3833 15.389C25.2851 15.2314 25.1447 15.1047 24.9779 15.0231C24.8112 14.9416 24.6249 14.9086 24.4403 14.9279L18.8132 15.8793C18.7378 15.8872 18.6656 15.9143 18.6037 15.9582C18.5419 16.0021 18.4923 16.0612 18.46 16.1298C18.4276 16.1984 18.4135 16.2742 18.4189 16.3499C18.4244 16.4255 18.4493 16.4985 18.4912 16.5617L21.0273 21.1621C21.127 21.3217 21.2672 21.4521 21.4336 21.5399C21.6001 21.6276 21.7869 21.6696 21.9749 21.6616L27.8594 21.0991Z"
        fill="#CD4C0A"
      />
      <path
        d="M24.0438 12.9489L23.5311 12.0182L22.5451 10.2276L21.2241 7.82982C21.1338 7.67754 20.9942 7.56067 20.8284 7.49861C20.6627 7.43655 20.4807 7.43302 20.3126 7.4886C16.9986 8.41641 13.8188 9.77004 10.8529 11.5156C9.85383 12.1781 8.98156 12.899 8.98156 12.899C8.66543 13.1768 8.39516 13.5028 8.18077 13.865L5.81219 18.1649C5.64389 18.4723 5.76685 18.6021 6.08732 18.4538C6.08732 18.4538 6.70213 18.1702 7.3938 17.8813C9.8662 16.8538 12.4069 15.9989 14.9975 15.3229L15.1512 15.2829C18.1484 14.5144 23.7187 13.6106 23.7187 13.6106C23.7936 13.6065 23.8661 13.5825 23.9288 13.5411C23.9914 13.4997 24.0418 13.4423 24.0749 13.3749C24.108 13.3076 24.1226 13.2326 24.1171 13.1577C24.1116 13.0828 24.0863 13.0108 24.0438 12.9489Z"
        fill="#E85A13"
      />
      <path
        d="M10.6954 9.9786C12.146 9.12452 13.6417 8.34925 15.1758 7.65614C16.7682 6.99522 19.5987 6.17674 19.5987 6.17674C19.6747 6.15923 19.7455 6.12383 19.8052 6.07348C19.8649 6.02313 19.9117 5.95928 19.9417 5.88722C19.9718 5.81517 19.9842 5.73699 19.978 5.65916C19.9718 5.58134 19.9471 5.50612 19.9061 5.43973L17.2854 0.679529C16.7474 -0.191202 15.9075 -0.156617 15.3895 0.784049L15.3833 0.788658L10.4502 9.74497C10.2811 10.0508 10.3918 10.1561 10.6954 9.9786Z"
        fill="#F38517"
      />
      <path
        d="M32.5843 28.4569L29.5586 22.9666C29.4874 22.8231 29.3819 22.6995 29.2515 22.6066C29.121 22.5137 28.9697 22.4544 28.8109 22.434L28.5872 22.4501C20.3726 22.9346 12.2335 24.2973 4.30897 26.5148C2.85878 26.939 1.15805 27.52 1.15805 27.52C0.956593 27.5692 0.769171 27.6641 0.610306 27.7974C0.451442 27.9306 0.325404 28.0987 0.241972 28.2886L0.152826 28.4545C-0.271396 29.3706 0.213538 30.0969 1.28562 30.0969L1.33942 30.103H31.453C32.5228 30.1007 33.0085 29.3722 32.5843 28.4569Z"
        fill="#CD4C0A"
      />
    </svg>
  );
};

export default SvgAnhangueraLogo;
