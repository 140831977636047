import { useState, useEffect } from 'react';

function useGetCourseOnURLParam() {
  const [selectedCourse, setSelectedCourse] = useState({
    course: '',
    courseDegree: '',
  });

  const courseDegreeENUM = {
    graduacao: 'UNDERGRADUATE',
    'pos-graduacao': 'GRADUATE',
    eja: 'EJA',
  };

  const getCourseOnURLParam = () => {
    const params = new URLSearchParams(window.location.search);
    for (const param of params) {
      if (param[0] === 'curso') selectedCourse.course = param[1];
      if (param[0] === 'tipo')
        selectedCourse.courseDegree = courseDegreeENUM[param[1]];
    }
    setSelectedCourse(selectedCourse);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const currentCourse = JSON.parse(
      sessionStorage.getItem('selectedCourseToSubscribe')
    );

    if (currentCourse) {
      setSelectedCourse(currentCourse);
    } else {
      getCourseOnURLParam();
    }
  }, []);

  return selectedCourse;
}

export default useGetCourseOnURLParam;
