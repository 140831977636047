import React from 'react';

import { cx, Logo } from '@ampli/ui';
import { string } from '@ampli/utils';
import { logoHorizontal } from './styles';
import CognaLogoHW from './cogna-horizontal-white';
import AnhangueraLogoH from './anhanguera-horizontal';

const CognaLogo = (props) => (
  <CognaLogoHW {...props} className={cx(logoHorizontal)} />
);
CognaLogo.propTypes = {
  className: string,
};

const AmpliLogo = (props) => (
  <Logo.HorizontalWhite {...props} className={cx(logoHorizontal)} />
);
AmpliLogo.propTypes = {
  className: string,
};

const AnhangueraLogo = (props) => (
  <AnhangueraLogoH {...props} className={cx(logoHorizontal)} />
);
AnhangueraLogo.propTypes = {
  className: string,
};

export { CognaLogo, AmpliLogo, AnhangueraLogo };
