import React, { FunctionComponent } from 'react';
import { cx } from '@ampli/ui';
import { string } from '@ampli/utils';

import DegreeCard from './degree-card';
import styles from './styles';
import { DefaultComponentWithClassNameProps } from '../../types';

const CardWrapper: FunctionComponent<DefaultComponentWithClassNameProps> = ({
  className,
  ...props
}: DefaultComponentWithClassNameProps) => {
  return (
    <div
      className={cx('card-wrapper', styles.cardWrapper, className)}
      {...props}
    >
      <div className={cx('card-wrapper-content', styles.cardWrapperContent)}>
        <DegreeCard
          illustration="Parachute"
          title="EJA"
          subtitle="(antigo Supletivo)"
          link="/cursos/eja"
        >
          Educação para Jovens e Adultos, do ensino fundamental ao ensino médio.
        </DegreeCard>
        <DegreeCard
          illustration="RunningMan"
          title="Graduação"
          link="/graduacao"
        >
          Cursos bacharelados, licenciaturas e tecnólogos a distância.
        </DegreeCard>
        <DegreeCard
          illustration="RunningLady"
          title="Pós-"
          subtitle="Graduação"
          link="/pos-graduacao"
        >
          Cursos de especialização e MBA online.
        </DegreeCard>
      </div>
      <div className={cx('card-wrapper-content', styles.cardWrapperContent)}>
        <DegreeCard
          illustration="PlayingTennis"
          title="Cursos"
          subtitle="Livres"
          link="https://cursolivre.ampli.com.br/"
          externalLink
          className={cx(styles.degreeCardCourses)}
        >
          Cursos de curta duração com certificado.
        </DegreeCard>
        <DegreeCard
          illustration="RunningGirl"
          title="Curso"
          subtitle="Técnico"
          link="/cursos/eja"
        >
          Cursos técnicos para entrar no mercado de trabalho em até 1 ano e
          meio.
        </DegreeCard>
      </div>
    </div>
  );
};

CardWrapper.propTypes = {
  className: string,
};

export default CardWrapper;
