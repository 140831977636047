import React from 'react';

import {
  useDialogState,
  RegressiveCounter,
  cx,
  css,
  mediaQuery,
} from '@ampli/ui';
import { string, shape, arrayOf, func, bool } from '@ampli/utils';
import { DesktopViewport, MobileViewport } from './base-header-viewports';
import HeaderContainer from './header-container';
import DialogMenu from './dialog-menu';
import { BlackWeekPopover } from '../black-week-popover';

import {
  HEADER_LINKS,
  AMPLI_BLACK_WEEK_END_DATE,
  MIN_WIDTH_DESKTOP_HEADER,
} from '../../constants';
import {
  hideOnMaxWidth,
  hideOnMinWidth,
  HideModes,
} from '../../lib/hide-component-by-viewport.ts';
import {
  useOpenBlackWeekPopupOnUserIdleTime,
  useOpenBlackWeekPopupOnUserMouseOut,
} from '../../hooks/use-black-week-events';

const hideOnDesktop = hideOnMinWidth(
  MIN_WIDTH_DESKTOP_HEADER,
  HideModes.ZERO_HEIGHT
);
const hideOnMobile = hideOnMaxWidth(
  MIN_WIDTH_DESKTOP_HEADER,
  HideModes.ZERO_HEIGHT
);

const BaseHeader = ({
  items,
  onSubscribeClick,
  onLoginClick,
  visibleSection,
  isBlackWeek,
  isInscriptionPage,
  className,
  ...props
}) => {
  const dialogMenuState = useDialogState();
  const dialogBlackWeekState = useDialogState();
  const isEJAPage =
    typeof window !== 'undefined' && window.location.href.includes('eja');

  const handleLoginClick = () => {
    if (!onLoginClick) return window?.open(HEADER_LINKS.login, '_blank');

    onLoginClick();
  };

  const checkSubscriptionLinkConfig = {
    href: HEADER_LINKS.followSubscription,
    target: '_blank',
  };

  const loginLinkConfig = {
    href: HEADER_LINKS.login,
    target: '_blank',
  };

  const onClickMobileMenu = () => {
    dialogMenuState[dialogMenuState.visible ? 'hide' : 'show']();
  };

  useOpenBlackWeekPopupOnUserIdleTime(dialogBlackWeekState, isEJAPage);
  useOpenBlackWeekPopupOnUserMouseOut(dialogBlackWeekState, isEJAPage);

  return (
    <>
      <HeaderContainer className={className}>
        <DesktopViewport
          items={items}
          loginLinkConfig={loginLinkConfig}
          checkSubscriptionLinkConfig={checkSubscriptionLinkConfig}
          onSubscribeClick={onSubscribeClick}
          visibleSection={visibleSection}
          className={hideOnMobile}
          isInscriptionPage={isInscriptionPage}
          {...props}
        />
        <MobileViewport
          onSubscribeClick={onSubscribeClick}
          onClickMenu={onClickMobileMenu}
          className={hideOnDesktop}
          {...props}
        />
        <DialogMenu
          items={items}
          onLoginClick={handleLoginClick}
          onSubscribeClick={onSubscribeClick}
          dialogState={dialogMenuState}
          isInscriptionPage={isInscriptionPage}
        />
      </HeaderContainer>
      {isBlackWeek && (
        <>
          <BlackWeekPopover dialog={dialogBlackWeekState} />
          <div
            className={cx(
              'regressive-counter-container',
              css`
                width: 100%;
                ${mediaQuery('desktop')} {
                  position: fixed;
                  z-index: 5;
                }
              `
            )}
          >
            <RegressiveCounter
              endDate={AMPLI_BLACK_WEEK_END_DATE}
              onClick={() => dialogBlackWeekState.show()}
            />
          </div>
        </>
      )}
    </>
  );
};

BaseHeader.propTypes = {
  items: arrayOf(
    shape({
      label: string,
      onClick: func,
    })
  ),
  onLoginClick: func,
  onFollowSubscriptionClick: func,
  onSubscribeClick: func,
  visibleSection: string,
  isBlackWeek: bool,
  isInscriptionPage: bool,
  className: string,
};

export default BaseHeader;
