import React from 'react';

const SvgSpider = (props) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_273_5637)">
        <path
          d="M16.4985 6.80505C16.5445 6.73987 16.577 6.66617 16.5942 6.58826C16.6114 6.50987 16.6128 6.4288 16.5981 6.34987C16.5835 6.27094 16.5532 6.19575 16.509 6.12875C16.4648 6.06175 16.4076 6.0043 16.3407 5.95981C16.2739 5.91532 16.1989 5.88469 16.12 5.86974C16.0411 5.85478 15.9601 5.8558 15.8816 5.87274C15.8031 5.88968 15.7288 5.92219 15.6632 5.96835C15.5979 6.01421 15.5425 6.07262 15.5001 6.14015L13.5016 9.13782C13.4557 9.203 13.4231 9.2767 13.406 9.35461C13.3887 9.433 13.3874 9.51406 13.402 9.59299C13.4166 9.67193 13.4469 9.74712 13.4912 9.81412C13.5354 9.88111 13.5926 9.93856 13.6594 9.98305C13.7262 10.0275 13.8013 10.0582 13.8802 10.0731C13.959 10.0881 14.0401 10.0871 14.1186 10.0701C14.197 10.0532 14.2713 10.0207 14.337 9.97452C14.4022 9.92866 14.4577 9.87025 14.5001 9.80272L16.4985 6.80505Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M18.1453 10.0534L18.1453 10.0535L18.1474 10.0529C18.2986 10.0114 18.4275 9.91249 18.5067 9.77721C18.5858 9.64193 18.6089 9.48106 18.5709 9.329C18.533 9.17694 18.4371 9.04575 18.3036 8.9635C18.1702 8.88125 18.0099 8.85447 17.857 8.88888L17.857 8.88885L17.8547 8.88942L13.8547 9.88942L13.8547 9.8894L13.8526 9.89C13.7014 9.93144 13.5725 10.0304 13.4933 10.1657C13.4142 10.3009 13.3911 10.4618 13.4291 10.6139C13.467 10.7659 13.5629 10.8971 13.6964 10.9794C13.8298 11.0616 13.9901 11.0884 14.143 11.054L14.143 11.054L14.1453 11.0534L18.1453 10.0534ZM16.6 1.47144C16.6 1.31231 16.5368 1.15969 16.4243 1.04717C16.3117 0.93465 16.1591 0.871436 16 0.871436C15.8409 0.871436 15.6883 0.93465 15.5757 1.04717C15.4632 1.15969 15.4 1.31231 15.4 1.47144V6.47144C15.4 6.63057 15.4632 6.78318 15.5757 6.8957C15.6883 7.00822 15.8409 7.07144 16 7.07144C16.1591 7.07144 16.3117 7.00822 16.4243 6.8957C16.5368 6.78318 16.6 6.63057 16.6 6.47144V1.47144Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M20.5565 4.69553C20.5874 4.62234 20.6035 4.54374 20.6039 4.46428C20.6042 4.38443 20.5886 4.30533 20.558 4.2316C20.5273 4.15786 20.4823 4.09099 20.4255 4.03488C20.3687 3.97878 20.3012 3.93458 20.2271 3.90487C20.153 3.87516 20.0737 3.86055 19.9939 3.86188C19.914 3.8632 19.8353 3.88045 19.7622 3.91261C19.6891 3.94477 19.6232 3.99119 19.5683 4.04915C19.5136 4.10686 19.471 4.17486 19.4428 4.24919L17.4435 9.24734C17.4126 9.32052 17.3965 9.39913 17.3961 9.4786C17.3958 9.55844 17.4114 9.63755 17.442 9.71128C17.4727 9.78501 17.5177 9.85189 17.5745 9.90799C17.6313 9.9641 17.6988 10.0083 17.7729 10.038C17.847 10.0677 17.9263 10.0823 18.0061 10.081C18.086 10.0797 18.1647 10.0624 18.2378 10.0303C18.3109 9.99811 18.3768 9.95169 18.4317 9.89372C18.4864 9.83601 18.529 9.76802 18.5572 9.69369L20.5565 4.69553ZM20 12.0714C20.1591 12.0714 20.3117 12.0082 20.4243 11.8957C20.5368 11.7832 20.6 11.6306 20.6 11.4714C20.6 11.3123 20.5368 11.1597 20.4243 11.0472C20.3117 10.9347 20.1591 10.8714 20 10.8714H14C13.8409 10.8714 13.6883 10.9347 13.5757 11.0472C13.4632 11.1597 13.4 11.3123 13.4 11.4714C13.4 11.6306 13.4632 11.7832 13.5757 11.8957C13.6883 12.0082 13.8409 12.0714 14 12.0714H20Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M23.4791 18.769L23.479 18.769L23.4803 18.7711C23.5612 18.9052 23.6914 19.0024 23.843 19.0418C23.9946 19.0813 24.1557 19.0598 24.2917 18.9821C24.4277 18.9044 24.528 18.7765 24.571 18.6259C24.614 18.4752 24.5963 18.3137 24.5219 18.1759L24.5219 18.1759L24.5207 18.1738L20.5207 11.1738L20.5207 11.1738L20.5195 11.1718C20.4386 11.0376 20.3084 10.9404 20.1568 10.901C20.0051 10.8616 19.8441 10.883 19.7081 10.9607C19.572 11.0385 19.4718 11.1663 19.4288 11.317C19.3858 11.4676 19.4034 11.6291 19.4779 11.767L19.4779 11.767L19.4791 11.769L23.4791 18.769ZM13.7776 13.0286L18.7758 15.0279C18.849 15.0588 18.9276 15.0749 19.007 15.0753C19.0869 15.0756 19.166 15.06 19.2397 15.0294C19.3135 14.9987 19.3803 14.9537 19.4364 14.8969C19.4925 14.8401 19.5367 14.7726 19.5664 14.6985C19.5962 14.6244 19.6108 14.5451 19.6094 14.4653C19.6081 14.3855 19.5909 14.3067 19.5587 14.2336C19.5265 14.1605 19.4801 14.0946 19.4222 14.0397C19.3645 13.985 19.2965 13.9424 19.2221 13.9142L14.224 11.915C14.1508 11.884 14.0722 11.8679 13.9927 11.8676C13.9129 11.8672 13.8338 11.8828 13.76 11.9135C13.6863 11.9441 13.6194 11.9891 13.5633 12.0459C13.5072 12.1028 13.463 12.1702 13.4333 12.2443C13.4036 12.3184 13.389 12.3977 13.3903 12.4775C13.3916 12.5574 13.4089 12.6361 13.4411 12.7092C13.4732 12.7823 13.5196 12.8482 13.5776 12.9031C13.6353 12.9578 13.7033 13.0005 13.7776 13.0286Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M20.418 22.6167L20.418 22.6167L20.4186 22.6189C20.46 22.77 20.5589 22.899 20.6942 22.9781C20.8295 23.0572 20.9904 23.0803 21.1424 23.0424C21.2945 23.0044 21.4257 22.9085 21.5079 22.7751C21.5902 22.6417 21.617 22.4814 21.5826 22.3285L21.5826 22.3285L21.582 22.3262L19.582 14.3262L19.582 14.3262L19.5814 14.324C19.54 14.1728 19.4411 14.0439 19.3058 13.9648C19.1705 13.8856 19.0096 13.8626 18.8576 13.9005C18.7055 13.9384 18.5743 14.0344 18.4921 14.1678C18.4098 14.3012 18.383 14.4615 18.4174 14.6144L18.4174 14.6144L18.418 14.6167L20.418 22.6167ZM8.50079 6.80391L10.5 9.80272C10.5424 9.87025 10.5979 9.92866 10.6631 9.97452C10.7288 10.0207 10.8031 10.0532 10.8815 10.0701C10.96 10.0871 11.0411 10.0881 11.1199 10.0731C11.1988 10.0582 11.2739 10.0275 11.3407 9.98306C11.4075 9.93856 11.4647 9.88112 11.5089 9.81412C11.5531 9.74712 11.5834 9.67193 11.5981 9.593C11.6127 9.51407 11.6114 9.433 11.5941 9.35461C11.5769 9.2767 11.5444 9.203 11.4984 9.13782L9.6 6.29016V1.47144C9.6 1.31231 9.53679 1.15969 9.42426 1.04717C9.31174 0.93465 9.15913 0.871436 9 0.871436C8.84087 0.871436 8.68826 0.93465 8.57574 1.04717C8.46321 1.15969 8.4 1.31231 8.4 1.47144V6.62244V6.65271L8.41679 6.67791L8.50079 6.80391Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M10.8547 11.0534L10.8547 11.0535L10.857 11.054C11.0099 11.0884 11.1702 11.0616 11.3037 10.9794C11.4371 10.8971 11.533 10.7659 11.5709 10.6139C11.6089 10.4618 11.5858 10.3009 11.5067 10.1657C11.4275 10.0304 11.2986 9.93144 11.1474 9.88999L11.1475 9.88997L11.1453 9.88942L7.1463 8.88968C7.06954 8.86879 6.98938 8.86338 6.91049 8.87376C6.83121 8.88419 6.75482 8.91036 6.6858 8.95074C6.61678 8.99112 6.55653 9.04488 6.50858 9.10888C6.46064 9.17287 6.42597 9.2458 6.40661 9.32339C6.38726 9.40097 6.3836 9.48164 6.39587 9.56066C6.40813 9.63968 6.43607 9.71544 6.47803 9.78351C6.52 9.85157 6.57515 9.91057 6.64023 9.95702C6.70499 10.0032 6.77828 10.0361 6.85584 10.0537L10.8547 11.0534Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M5.55685 4.24829L5.55687 4.24829L5.55603 4.2463C5.49461 4.10185 5.37902 3.98722 5.23406 3.92699C5.0891 3.86677 4.92632 3.86575 4.78062 3.92416C4.63492 3.98256 4.51791 4.09574 4.45469 4.23942C4.39146 4.38309 4.38706 4.54582 4.44243 4.6927L4.44241 4.69271L4.44315 4.69457L6.44315 9.69457L6.44313 9.69458L6.44397 9.69656C6.50539 9.84102 6.62098 9.95565 6.76594 10.0159C6.9109 10.0761 7.07368 10.0771 7.21938 10.0187C7.36508 9.9603 7.48209 9.84712 7.54531 9.70345C7.60854 9.55977 7.61294 9.39704 7.55757 9.25016L7.55759 9.25016L7.55685 9.24829L5.55685 4.24829ZM5 10.8714C4.84087 10.8714 4.68826 10.9346 4.57574 11.0472C4.46321 11.1597 4.4 11.3123 4.4 11.4714C4.4 11.6306 4.46321 11.7832 4.57574 11.8957C4.68826 12.0082 4.84087 12.0714 5 12.0714H11C11.1591 12.0714 11.3117 12.0082 11.4243 11.8957C11.5368 11.7832 11.6 11.6306 11.6 11.4714C11.6 11.3123 11.5368 11.1597 11.4243 11.0472C11.3117 10.9346 11.1591 10.8714 11 10.8714H5Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M0.479166 18.1738L0.479141 18.1738L0.478016 18.1759C0.403526 18.3137 0.385902 18.4752 0.428911 18.6259C0.471919 18.7765 0.572148 18.9044 0.708177 18.9821C0.844206 19.0598 1.00524 19.0813 1.15687 19.0418C1.3085 19.0024 1.43869 18.9052 1.51962 18.7711L1.51964 18.7711L1.52081 18.769L5.52081 11.769L5.52084 11.769L5.52196 11.767C5.59645 11.6291 5.61408 11.4676 5.57107 11.317C5.52806 11.1663 5.42783 11.0385 5.2918 10.9607C5.15577 10.883 4.99474 10.8616 4.84311 10.901C4.69148 10.9404 4.56129 11.0376 4.48036 11.1718L4.48034 11.1717L4.47917 11.1738L0.479166 18.1738ZM5.77774 13.9142C5.70341 13.9424 5.63542 13.985 5.57771 14.0397C5.51974 14.0946 5.47332 14.1605 5.44116 14.2336C5.409 14.3067 5.39176 14.3855 5.39043 14.4653C5.3891 14.5451 5.40372 14.6244 5.43342 14.6985C5.46313 14.7726 5.50733 14.8401 5.56343 14.8969C5.61954 14.9537 5.68642 14.9987 5.76015 15.0294C5.83388 15.06 5.91299 15.0756 5.99283 15.0753C6.07229 15.0749 6.1509 15.0588 6.22408 15.0279L11.2222 13.0286C11.2966 13.0005 11.3646 12.9578 11.4223 12.9031C11.4802 12.8482 11.5267 12.7823 11.5588 12.7092C11.591 12.6361 11.6082 12.5574 11.6096 12.4775C11.6109 12.3977 11.5963 12.3184 11.5666 12.2443C11.5368 12.1702 11.4926 12.1028 11.4365 12.0459C11.3804 11.9891 11.3136 11.9441 11.2398 11.9135C11.1661 11.8828 11.087 11.8672 11.0072 11.8676C10.9277 11.8679 10.8491 11.884 10.7759 11.915L5.77774 13.9142Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M3.41787 22.3262L3.41784 22.3262L3.41732 22.3285C3.38292 22.4814 3.4097 22.6417 3.49195 22.7751C3.5742 22.9085 3.70538 23.0044 3.85745 23.0424C4.00951 23.0803 4.17038 23.0572 4.30566 22.9781C4.44094 22.899 4.53988 22.77 4.58132 22.6189L4.58135 22.6189L4.5819 22.6167L6.5819 14.6167L6.58193 14.6167L6.58245 14.6144C6.61685 14.4615 6.59007 14.3012 6.50782 14.1678C6.42557 14.0344 6.29439 13.9384 6.14232 13.9005C5.99026 13.8626 5.82939 13.8856 5.69411 13.9648C5.55884 14.0439 5.45989 14.1728 5.41845 14.324L5.41842 14.324L5.41787 14.3262L3.41787 22.3262ZM17.5999 19.4714C17.5999 17.58 17.022 15.6861 16.1001 14.2627C15.1799 12.8421 13.9032 11.8714 12.4999 11.8714C11.0966 11.8714 9.81987 12.8421 8.8997 14.2627C7.97775 15.6861 7.39989 17.58 7.39989 19.4714C7.39989 21.1895 7.91871 22.5905 8.8203 23.5629C9.72246 24.536 11.0001 25.0714 12.4999 25.0714C13.9997 25.0714 15.2773 24.536 16.1795 23.5629C17.0811 22.5905 17.5999 21.1895 17.5999 19.4714ZM8.59988 19.4714C8.59988 17.8581 9.0879 16.2512 9.825 15.0497C10.5656 13.8426 11.5393 13.0714 12.4999 13.0714C13.4604 13.0714 14.4342 13.8426 15.1748 15.0497C15.9119 16.2512 16.3999 17.8581 16.3999 19.4714C16.3999 20.878 16.0033 21.9768 15.323 22.7228C14.6436 23.4679 13.6719 23.8714 12.4999 23.8714C11.3279 23.8714 10.3562 23.4679 9.67678 22.7228C8.9965 21.9768 8.59988 20.878 8.59988 19.4714Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M14.6 10.4714C14.6 9.08516 13.7165 7.87144 12.5 7.87144C11.2835 7.87144 10.4 9.08516 10.4 10.4714C10.4 11.8577 11.2835 13.0714 12.5 13.0714C13.7165 13.0714 14.6 11.8577 14.6 10.4714ZM11.6 10.4714C11.6 10.0621 11.716 9.70742 11.8887 9.45771C12.0626 9.20642 12.2855 9.07144 12.5 9.07144C12.7145 9.07144 12.9374 9.20642 13.1113 9.45771C13.284 9.70742 13.4 10.0621 13.4 10.4714C13.4 10.8808 13.284 11.2355 13.1113 11.4852C12.9374 11.7365 12.7145 11.8714 12.5 11.8714C12.2855 11.8714 12.0626 11.7365 11.8887 11.4852C11.716 11.2355 11.6 10.8808 11.6 10.4714Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
        <path
          d="M10.9147 7.6022C10.955 7.78605 11.0418 8.03144 11.196 8.28339L11.1958 8.28359C11.4833 8.7538 11.9183 9.07141 12.5001 9.07141C13.0829 9.07141 13.5168 8.75376 13.8044 8.28359L13.8044 8.28359C13.9587 8.03127 14.0444 7.78497 14.0865 7.60182L14.0867 7.601C14.1037 7.52403 14.1054 7.44446 14.0917 7.36683C14.0779 7.2892 14.0491 7.21504 14.0067 7.14858C13.9643 7.08212 13.9092 7.02466 13.8446 6.97948C13.78 6.9343 13.7071 6.90229 13.6302 6.88527C13.5532 6.86825 13.4736 6.86657 13.396 6.8803C13.3184 6.89403 13.2442 6.92292 13.1777 6.96532C13.1113 7.00771 13.0538 7.06278 13.0086 7.12739C12.9638 7.19151 12.9319 7.26378 12.9148 7.34013C12.886 7.45225 12.8405 7.55942 12.7799 7.65805L12.7796 7.65854C12.6803 7.82213 12.5912 7.87141 12.5001 7.87141C12.4088 7.87141 12.3197 7.82199 12.2204 7.65933L12.2203 7.6591C12.1598 7.56054 12.1144 7.45346 12.0857 7.34145C12.0703 7.26382 12.0397 7.18999 11.9957 7.12421C11.9511 7.05756 11.8936 7.00053 11.8266 6.95649C11.7596 6.91244 11.6844 6.88228 11.6056 6.86778C11.5267 6.85328 11.4457 6.85473 11.3674 6.87206C11.2891 6.88939 11.2151 6.92224 11.1497 6.96866C11.0843 7.01509 11.0289 7.07415 10.9867 7.14236C10.9446 7.21057 10.9165 7.28654 10.9042 7.36578C10.892 7.44451 10.8955 7.52488 10.9147 7.6022ZM12.1351 7.71141C12.0688 7.60348 12.0193 7.48616 11.9881 7.36342C11.9754 7.29779 11.9498 7.23536 11.9126 7.17982C11.8755 7.12428 11.8275 7.07676 11.7717 7.04005C11.7158 7.00335 11.6532 6.97821 11.5875 6.96613C11.5218 6.95404 11.4543 6.95526 11.389 6.9697C11.3238 6.98414 11.2621 7.01151 11.2076 7.0502C11.1531 7.08889 11.1069 7.13811 11.0718 7.19495C11.0366 7.25179 11.0133 7.3151 11.003 7.38113C10.9927 7.44717 10.9958 7.51459 11.0121 7.57941L12.1351 7.71141Z"
          fill="#FFEBD9"
          stroke="#FFEBD9"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_273_5637">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.971436)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgSpider;
