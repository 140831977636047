import { injectGlobal, px2grid } from '@ampli/ui';

injectGlobal`
:root {
  --detail-card-width: ${px2grid(325)};
}

.grecaptcha-badge { visibility: hidden; }

#blip-chat-container {
  display: block !important;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}
`;
