import React from 'react';
import styles from './styles';
import { cx } from '@ampli/ui';
import { useCheckBlackWeekDate } from '../../hooks';

interface SubscriptionContentProps {
  isInscriptionThirdPage?: boolean;
}

const SubscriptionContent: React.FC<SubscriptionContentProps> = ({
  children,
  isInscriptionThirdPage = false,
}) => {
  const isBlackWeek = useCheckBlackWeekDate();

  return (
    <div className={cx('subscription-page-container', styles.container)}>
      <div
        className={cx(
          'subscription-page-content',
          styles.content(isBlackWeek, isInscriptionThirdPage)
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default SubscriptionContent;
