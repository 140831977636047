var firstName = {
  read: function read(cached, _ref) {
    var readField = _ref.readField;
    if (cached) return cached;
    var fullName = readField('fullName');
    return (fullName === null || fullName === void 0 ? void 0 : fullName.split(' ')[0]) || '';
  }
};
var lastName = {
  read: function read(cached, _ref2) {
    var readField = _ref2.readField;
    if (cached) return cached;
    var fullName = readField('fullName');
    return (fullName === null || fullName === void 0 ? void 0 : fullName.split(' ').at(-1)) || '';
  }
};
var phoneNumberString = {
  read: function read(cached, _ref3) {
    var readField = _ref3.readField;
    if (cached) return cached;
    var phoneNumber = readField('phoneNumber') || {};
    return phoneNumber.areaCode + phoneNumber.number;
  }
};
export default {
  fields: {
    firstName: firstName,
    lastName: lastName,
    phoneNumberString: phoneNumberString
  }
};