import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
var _excluded = ["loading", "data"],
    _excluded2 = ["data", "loading"],
    _excluded3 = ["data", "loading"],
    _excluded4 = ["data", "loading"],
    _excluded5 = ["data", "loading"],
    _excluded6 = ["data", "loading"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState } from 'react';
import { sortBy } from '@ampli/utils';
import { useQuery, useMutation } from '../hooks';
import { FIND_COURSES_STUB, GET_COURSE, GET_COURSE_BY_URL_CODE, GET_COURSE_ENROLLMENT, GET_COURSE_PRICE_STUB, GET_COURSE_PRICE_STUB_BY_ID, GET_COURSE_ID_AND_PRICE_BY_URL_CODE, GET_COURSE_STUB, GET_DEFAULT_COURSE_PRICE_BY_COURSE_ID, LIST_COURSES_BY_COURSE_DEGREE, LIST_FIELD_OF_STUDY, LIST_POPULAR_COURSES_BY_DEGREE, EXISTS_COURSE_ENROLLMENT_BY_COURSE_ID, CANCEL_COURSE_ENROLLMENT, STUDENT_FREE_TRIAL_INFO, LIST_VISIBLE_COURSES_BY_COURSE_DEGREE, LIST_VISIBLE_COURSES_CATEGORIES_BY_DEGREE, GET_COURSE_ENROLLMENT_FOR_TUTOR_PAGE, GET_COURSE_ENROLLMENT_FOR_SYLLABUS_PAGE, GET_COURSE_ENROLLMENT_HOME, GET_SUBJECT_ENROLLMENTS_FOR_SYLLABUS_PAGE } from './queries';
var cacheFetchPolicy = 'cache-first';
export var useFindCourses = function useFindCourses() {
  var _useQuery = useQuery(FIND_COURSES_STUB),
      loading = _useQuery.loading,
      data = _useQuery.data;

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      course = _useState2[0],
      setCourse = _useState2[1];

  var courses = loading || !data ? [] : sortBy(data.list, 'name');
  return {
    courses: courses,
    course: course,
    setCourse: setCourse,
    loading: loading
  };
};
export var useFindCoursesByCourseDegree = function useFindCoursesByCourseDegree(degree) {
  var pagination = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    page: 1,
    perPage: 50
  };
  var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    field: 'name',
    order: 'ASC'
  };
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _useQuery2 = useQuery(LIST_COURSES_BY_COURSE_DEGREE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      degree: degree,
      pagination: pagination,
      sort: sort
    },
    skip: !degree,
    fetchPolicy: (options === null || options === void 0 ? void 0 : options.fetchPolicy) || 'no-cache'
  })),
      loading = _useQuery2.loading,
      data = _useQuery2.data;

  var courses = loading || !data ? {} : data.list.data;
  return {
    courses: courses,
    loading: loading
  };
};
export var useFindVisibleCoursesByCourseDegree = function useFindVisibleCoursesByCourseDegree(degree) {
  var pagination = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    page: 1,
    perPage: 50
  };
  var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    field: 'name',
    order: 'ASC'
  };
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _useQuery3 = useQuery(LIST_VISIBLE_COURSES_BY_COURSE_DEGREE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      degree: degree,
      pagination: pagination,
      sort: sort
    },
    skip: !degree,
    fetchPolicy: (options === null || options === void 0 ? void 0 : options.fetchPolicy) || 'no-cache'
  })),
      loading = _useQuery3.loading,
      data = _useQuery3.data;

  var courses = loading || !data ? {} : data.list.data;
  return {
    courses: courses,
    loading: loading
  };
};
export var useGetCourseStub = function useGetCourseStub(courseId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery4 = useQuery(GET_COURSE_STUB, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseId: courseId
    },
    skip: !courseId
  })),
      loading = _useQuery4.loading,
      data = _useQuery4.data;

  var course = loading || !data ? {} : data.data;
  return {
    course: course,
    loading: loading
  };
};
export var useGetCoursePrice = function useGetCoursePrice(cityId, courseId) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _useQuery5 = useQuery(GET_COURSE_PRICE_STUB, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      cityId: parseInt(cityId, 10),
      courseId: courseId
    },
    skip: !cityId || !courseId,
    fetchPolicy: 'no-cache'
  })),
      loading = _useQuery5.loading,
      data = _useQuery5.data;

  var coursePrice = loading || !data ? {} : data.data;
  return {
    coursePrice: coursePrice,
    loading: loading
  };
};
export var useGetCoursePriceById = function useGetCoursePriceById(coursePriceId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery6 = useQuery(GET_COURSE_PRICE_STUB_BY_ID, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      coursePriceId: coursePriceId
    },
    skip: !coursePriceId
  })),
      loading = _useQuery6.loading,
      data = _useQuery6.data,
      rest = _objectWithoutProperties(_useQuery6, _excluded);

  var coursePrice = loading || !data ? {} : data.data;
  return _objectSpread({
    data: coursePrice,
    loading: loading
  }, rest);
};
export var useFindPopularCoursesByDegree = function useFindPopularCoursesByDegree() {
  var degree = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'UNDERGRADUATE';
  var perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  var _useQuery7 = useQuery(LIST_POPULAR_COURSES_BY_DEGREE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      degree: degree,
      pagination: {
        page: 1,
        perPage: 1000
      },
      sort: {
        field: 'name',
        order: 'ASC'
      }
    },
    skip: !degree,
    fetchPolicy: 'no-cache'
  })),
      loading = _useQuery7.loading,
      data = _useQuery7.data;

  var popularCourses = loading || !data ? {} : data.list.data.sort(function (a, b) {
    return (a === null || a === void 0 ? void 0 : a.candidateCount) > (b === null || b === void 0 ? void 0 : b.candidateCount);
  }).slice(0, perPage);
  return {
    popularCourses: popularCourses,
    loading: loading
  };
};
export var useFindListFieldOfStudy = function useFindListFieldOfStudy() {
  var _data$list;

  var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var pagination = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    page: 1,
    perPage: 1000
  };
  var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    field: 'name',
    order: 'ASC'
  };
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _useQuery8 = useQuery(LIST_FIELD_OF_STUDY, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      filter: filter,
      pagination: pagination,
      sort: sort
    }
  })),
      loading = _useQuery8.loading,
      data = _useQuery8.data;

  var fieldsOfStudy = loading || !data ? {} : (data === null || data === void 0 ? void 0 : (_data$list = data.list) === null || _data$list === void 0 ? void 0 : _data$list.data) || [];
  return {
    fieldsOfStudy: fieldsOfStudy,
    loading: loading
  };
};
export var useGetCourseIdAndPriceByUrlCode = function useGetCourseIdAndPriceByUrlCode(urlCode) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery9 = useQuery(GET_COURSE_ID_AND_PRICE_BY_URL_CODE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      urlCode: urlCode
    },
    skip: !urlCode
  })),
      loading = _useQuery9.loading,
      data = _useQuery9.data;

  var courseInfo = loading || !data ? {} : data.data;
  return {
    courseInfo: courseInfo,
    loading: loading
  };
};
export var useGetCourseByUrlCode = function useGetCourseByUrlCode(urlCode) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery10 = useQuery(GET_COURSE_BY_URL_CODE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      urlCode: urlCode
    },
    skip: !urlCode
  })),
      loading = _useQuery10.loading,
      data = _useQuery10.data;

  var course = loading || !data ? {} : data.data;
  return {
    course: course,
    loading: loading
  };
};
export var useGetCourse = function useGetCourse(courseId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery11 = useQuery(GET_COURSE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseId: courseId
    },
    skip: !courseId
  })),
      loading = _useQuery11.loading,
      data = _useQuery11.data;

  var course = loading || !data ? {} : data.data;
  return {
    course: course,
    loading: loading
  };
};
export var useGetDefaultCoursePriceByCourseId = function useGetDefaultCoursePriceByCourseId(courseId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery12 = useQuery(GET_DEFAULT_COURSE_PRICE_BY_COURSE_ID, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseId: courseId
    },
    skip: !courseId
  })),
      loading = _useQuery12.loading,
      data = _useQuery12.data;

  var defaultCoursePrice = loading || !data ? {} : data.data;
  return {
    defaultCoursePrice: defaultCoursePrice,
    loading: loading
  };
};
export var useGetCourseEnrollmentHome = function useGetCourseEnrollmentHome(courseEnrollmentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery13 = useQuery(GET_COURSE_ENROLLMENT_HOME, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId: courseEnrollmentId
    },
    skip: !courseEnrollmentId,
    fetchPolicy: cacheFetchPolicy
  })),
      data = _useQuery13.data,
      loading = _useQuery13.loading,
      rest = _objectWithoutProperties(_useQuery13, _excluded2);

  var courseEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading: loading
  }, rest);
};
export var useGetCourseEnrollment = function useGetCourseEnrollment(courseEnrollmentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery14 = useQuery(GET_COURSE_ENROLLMENT, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId: courseEnrollmentId
    },
    skip: !courseEnrollmentId,
    fetchPolicy: cacheFetchPolicy
  })),
      data = _useQuery14.data,
      loading = _useQuery14.loading,
      rest = _objectWithoutProperties(_useQuery14, _excluded3);

  var courseEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading: loading
  }, rest);
};
export var useGetCourseEnrollmentForTutorPage = function useGetCourseEnrollmentForTutorPage(courseEnrollmentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery15 = useQuery(GET_COURSE_ENROLLMENT_FOR_TUTOR_PAGE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId: courseEnrollmentId
    },
    skip: !courseEnrollmentId,
    fetchPolicy: cacheFetchPolicy
  })),
      data = _useQuery15.data,
      loading = _useQuery15.loading,
      rest = _objectWithoutProperties(_useQuery15, _excluded4);

  var courseEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading: loading
  }, rest);
};
export var useGetCourseEnrollmentForSyllabusPage = function useGetCourseEnrollmentForSyllabusPage(courseEnrollmentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery16 = useQuery(GET_COURSE_ENROLLMENT_FOR_SYLLABUS_PAGE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId: courseEnrollmentId
    },
    skip: !courseEnrollmentId,
    fetchPolicy: cacheFetchPolicy
  })),
      data = _useQuery16.data,
      loading = _useQuery16.loading,
      rest = _objectWithoutProperties(_useQuery16, _excluded5);

  var courseEnrollment = loading || !(data !== null && data !== void 0 && data.data) ? {} : data === null || data === void 0 ? void 0 : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading: loading
  }, rest);
};
export var useGetSubjectEnrollmentsForSyllabusPage = function useGetSubjectEnrollmentsForSyllabusPage(courseEnrollmentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery17 = useQuery(GET_SUBJECT_ENROLLMENTS_FOR_SYLLABUS_PAGE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId: courseEnrollmentId
    },
    skip: !courseEnrollmentId,
    fetchPolicy: cacheFetchPolicy
  })),
      data = _useQuery17.data,
      loading = _useQuery17.loading,
      rest = _objectWithoutProperties(_useQuery17, _excluded6);

  var courseEnrollment = loading || !(data !== null && data !== void 0 && data.data) ? {} : data === null || data === void 0 ? void 0 : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading: loading
  }, rest);
};
export var useCancelCourseEnrollment = function useCancelCourseEnrollment(courseEnrollmentId, reason, elaboration) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _useMutation = useMutation(CANCEL_COURSE_ENROLLMENT, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId: courseEnrollmentId,
      reason: reason,
      elaboration: elaboration
    },
    context: {
      autoHandle: true
    }
  })),
      _useMutation2 = _slicedToArray(_useMutation, 2),
      mutation = _useMutation2[0],
      metadata = _useMutation2[1];

  return [mutation, metadata];
};
export var useFindStudentCourseEnrollmentsWithFreeTrialInfo = function useFindStudentCourseEnrollmentsWithFreeTrialInfo(studentId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useQuery(STUDENT_FREE_TRIAL_INFO, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      studentId: studentId
    },
    skip: !studentId
  }));
};
export var useExistsCourseEnrollmentByCourseId = function useExistsCourseEnrollmentByCourseId(courseId) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useQuery(EXISTS_COURSE_ENROLLMENT_BY_COURSE_ID, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseId: courseId
    },
    skip: !courseId
  }));
};
export var useFindVisibleCourseCategoriesByDegree = function useFindVisibleCourseCategoriesByDegree(courseDegree) {
  var pagination = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    page: 1,
    perPage: 50
  };
  var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    field: 'name',
    order: 'ASC'
  };
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _useQuery18 = useQuery(LIST_VISIBLE_COURSES_CATEGORIES_BY_DEGREE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseDegree: courseDegree,
      pagination: pagination,
      sort: sort
    },
    skip: !courseDegree,
    fetchPolicy: (options === null || options === void 0 ? void 0 : options.fetchPolicy) || 'no-cache'
  })),
      loading = _useQuery18.loading,
      data = _useQuery18.data;

  var categories = loading || !data ? {} : data.list.data;
  return {
    categories: categories,
    loading: loading
  };
};