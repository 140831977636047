import { Interval } from '@ampli/utils';
var durationInMinutes = {
  read: function read(originalValue, _ref) {
    var readField = _ref.readField;
    var finishedDate = readField('finishedDate');

    if (finishedDate) {
      var startedDate = readField('startedDate');
      var interval = Interval.fromISO("".concat(startedDate, "/").concat(finishedDate));
      return parseInt(interval.length('minutes'), 10);
    }

    return originalValue;
  }
};
export default {
  fields: {
    durationInMinutes: durationInMinutes
  }
};