/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useHideMundialeChatbot } from '@ampli/hooks';
import { SubscriptionFirstStep } from '@ampli/registration';
import { recaptchaSiteKey, googleAPIKey } from '../../config';
import { Text } from '@ampli/ui';
import {
  SubscriptionContent,
  Header,
  Helmet,
  BannerHeader,
} from '../../components';
import {
  STORAGE_SUBSCRIPTION_FORM,
  CONTACT,
  FREE_TRIAL_MD5_HASH,
} from '../../constants';
import { updateSubscriptionCache } from '../../lib/subscription-functions';

const SubscriptionPageOne: React.FC = () => {
  useHideMundialeChatbot();
  const history = useHistory();
  const [firstStepData, setFirstStepData] = useState({});
  const currentStep = 0;

  let urlParams;
  let queryString;

  let courseParam;
  let courseDegreeParam;

  let courseSelected;
  let courseDataParamSelected;
  let subscriptionSecondStepItem;

  if (typeof window !== 'undefined') {
    queryString = window.location.search;
    urlParams = new URLSearchParams(window.location.search);

    courseSelected = sessionStorage.getItem('selectedCourseToSubscribe');
    courseDataParamSelected = sessionStorage.getItem(
      'courseParamDataToSubscribe'
    );
    subscriptionSecondStepItem = sessionStorage.setItem(
      'subscriptionSecondStep',
      'completed'
    );
  }

  if (urlParams) {
    courseParam = urlParams.get('curso');
    courseDegreeParam = urlParams.get('tipo');
  }

  const storageCourseParamData = () => {
    const courseDetail = {
      course: courseParam,
      courseDegree: courseDegreeParam && courseDegreeENUM[courseDegreeParam],
    };
    sessionStorage.setItem(
      'courseParamDataToSubscribe',
      JSON.stringify(courseDetail)
    );
  };

  useEffect(() => {
    if (courseParam && courseDegreeParam) {
      storageCourseParamData();
    }
  }, [courseParam, courseDegreeParam]);
  const courseDegreeENUM = {
    graduacao: 'UNDERGRADUATE',
    'pos-graduacao': 'GRADUATE',
    eja: 'EJA',
  };

  const fetchSubscriptionCache = () => {
    const subscriptionForm = JSON.parse(
      localStorage.getItem(STORAGE_SUBSCRIPTION_FORM) as string
    );
    setFirstStepData(subscriptionForm?.firstStepData);
  };

  const checkIfIsFreeTrialUser = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(FREE_TRIAL_MD5_HASH);
    if (param) sessionStorage.setItem('isFreeTrialUser', param);
  };

  useEffect(() => {
    checkIfIsFreeTrialUser();
    fetchSubscriptionCache();
  }, []);

  const setSubscriptionSteps = () => {
    sessionStorage.setItem('subscriptionFirstStep', 'completed');
    courseSelected ? subscriptionSecondStepItem : null;
    courseDataParamSelected ? subscriptionSecondStepItem : null;
  };

  const subscriptionRedirectToNextStep = () => {
    if (courseSelected || courseDataParamSelected) {
      history.push('/inscricao/dados-pessoais');
    } else {
      history.push(`/inscricao/curso${queryString}`);
    }
  };

  const onCompleted = (data) => {
    setSubscriptionSteps();
    updateSubscriptionCache(currentStep, data);
    subscriptionRedirectToNextStep();
  };

  return (
    <div>
      <Helmet title="Inscrição Ampli" robots="noindex" />
      <BannerHeader />
      <Header
        pageTopScroller={''}
        isInscriptionPage={true}
        contactScroller={() => window?.open(CONTACT.WHATSAPP, '_blank')}
      />
      <SubscriptionContent>
        <Text
          fontWeight="700"
          size="36px"
          color="neutral-title"
          as="h1"
          marginSize={'48px'}
        >
          Inscreva-se agora!
        </Text>
        <SubscriptionFirstStep
          formSettings={{ recaptchaSiteKey, googleAPIKey }}
          candidateAdditionalData={{
            firstStepData,
          }}
          onCompleted={onCompleted}
          buttonText="Próxima etapa"
        />
      </SubscriptionContent>
    </div>
  );
};

export default SubscriptionPageOne;
