import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';

var useRegressiveCounter = function useRegressiveCounter(endDate) {
  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      totalRemainingTimeArray = _useState2[0],
      setTotalRemainingTimeArray = _useState2[1];

  var second = 1000;
  var minute = second * 60;
  var hour = minute * 60;
  var day = hour * 24;
  useEffect(function () {
    setInterval(function () {
      calculateRemainingTime(endDate || new Date());
    }, 1000);
  }, []);

  function calculateRemainingTime(date) {
    var today = new Date();
    var timeRemainingInMilliseconds = Math.abs(today - date);
    setTotalRemainingTimeArray([{
      title: 'Dias',
      time: Math.floor(timeRemainingInMilliseconds / day)
    }, {
      title: 'Horas',
      time: Math.floor(timeRemainingInMilliseconds % day / hour)
    }, {
      title: 'Min',
      time: Math.floor(timeRemainingInMilliseconds % hour / minute)
    }, {
      title: 'Seg',
      time: Math.floor(timeRemainingInMilliseconds % minute / second)
    }]);
  }

  return totalRemainingTimeArray;
};

export default useRegressiveCounter;