import {
  LIST_COURSES_URL_CODE_BY_DEGREE,
  LIST_COURSES_CATEGORIES_CATEGORY_CODE_BY_DEGREE,
  graphQLRequest,
} from '@ampli/services';
import { graphqlUrl } from '../config';
import { CourseDegree } from '../types';

export function generateSitemapByURLCode(
  degree: CourseDegree
): Promise<string[]> {
  return graphQLRequest(graphqlUrl, LIST_COURSES_URL_CODE_BY_DEGREE, {
    degree,
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'urlCode', order: 'ASC' },
  }).then((data) => data?.list?.data.map((i) => i.urlCode));
}

export function generateSitemapByCategoryUrlCode(
  degree: CourseDegree
): Promise<string[]> {
  return graphQLRequest(
    graphqlUrl,
    LIST_COURSES_CATEGORIES_CATEGORY_CODE_BY_DEGREE,
    {
      degree,
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
    }
  ).then((data) => data?.list?.data.map((i) => i.urlCode));
}
