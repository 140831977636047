import { css, getColor, mediaQuery, px2grid } from '@ampli/ui';

export default {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${px2grid(32)} 0px 0px;
    background: ${getColor('neutral-title')};
    border-radius: 8px;
    button {
      color: white;
    }
  `,
  section: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${px2grid(30)};
  `,
  button: css`
    width: 100%;
    padding: ${px2grid(16)} ${px2grid(32)};
    background: black;
    margin-top: ${px2grid(10)};
    border-radius: 32px;

    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: ${getColor('semantic-alert-lighter')};
    border: none;
    ${mediaQuery('mobile')} {
      margin-top: ${px2grid(5)};
    }
  `,
  spiderIcon: css`
    margin-left: ${px2grid(16)};
  `,
  footer: css`
    background: ${getColor('semantic-alert')};
    width: 100%;
  `,
  regressiveCounter: css`
    flex-direction: column;
    height: auto;
    ${mediaQuery('desktop')} {
      margin-top: ${px2grid(-8)};
      margin-bottom: ${px2grid(5)};
    }
    p {
      color: black;
      margin: 0px;
    }
    div {
      font-weight: 600;
      color: black;
      font-size: 24px;
      div {
        margin: 0px ${px2grid(7)};
        span:first-child {
          height: ${px2grid(40)};
          font-weight: 600;
        }
      }
    }
  `,
  logo: css`
    margin-bottom: ${px2grid(20)};
    width: ${px2grid(270)};
    height: ${px2grid(170)};
  `,
  description: css`
    padding-inline: ${px2grid(6)};
  `,
  dottedIllustration: css`
    width: 100%;
  `,
};
