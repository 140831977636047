// THIS PACKAGE IS DEPRECATED!!
// Please refer to @ampli/icon for all future icon updates
import Calendar from "./calendar";
import Speed from "./speed";
import PhonePlay from "./phone-play";
import Message from "./message";
import HatGraduation from "./hat-graduation";
import Check from "./check";
import Facebook from "./facebook";
import Instagram from "./instagram";
import LinkedIn from "./linkedin";
import Mail from "./mail";
import Phone from "./phone";
import MapPin from "./map-pin";
import List from "./list";
import X from "./x";
import AlertCircle from "./alert-circle";
import AlertCircleBold from "./alert-circle-bold";
import CheckCircle from "./check-circle";
import Radio from "./radio";
import InfoCircleBold from "./info-circle-bold";
import Checkbox from "./checkbox";
import Clock from "./clock";
import MessageCircle from "./message-circle";
import CalendarSimple from "./calendar-simple";
import Star from "./star";
import User from "./user";
import Home from "./home";
import Paper from "./paper";
import School from "./school";
import Whatsapp from "./whatsapp";
import Document from "./document";
import UncheckedCircle from "./unchecked-circle";
import DoNotDisturb from "./do-not-disturb";
import Iphone from "./iphone";
import Heart from "./heart";
import Write from "./write";
import Vector from "./vector";
import Place from "./place";
import Chat from "./chat";
import Wifi from "./wifi";
import Library from "./library";
import Money from "./money";
import Bell from "./bell";
import SmartPhone from "./smartphone";
import DownloadCloud from "./download-cloud";
import Certificate from "./certificate";
import Award from "./award";
import ArrowDown from "./arrow-down";
import ArrowUp from "./arrow-up";
import SeatedWoman from "./seated-woman";
import SeatedWomanBig from "./seated-woman-big";
import ArrowLeft from "./arrow-left";
import Dot from "./dot";
import Eye from "./eye";
import Folder from "./folder";
import DollarSign from "./dollar-sign";
import SpeechBalloon from "./speech-balloon";
import GreaterThan from "./greater-than";
import LowerThan from "./lower-than";
import ArrowRight from "./arrow-right";
import ArrowLineRight from "./arrow-line-right";
import PersonReading from "./person-reading";
import Pen from "./pen";
import FileText from "./file-text";
import Pdf from "./pdf";
import ArrowDownCircle from "./arrow-down-circle";
import Objectives from "./objectives";
import Introduction from "./introduction";
import Content from "./content";
import One from "./one";
import Two from "./two";
import Three from "./three";
import Four from "./four";
import Five from "./five";
import Six from "./six";
import HelpBallon from "./help-ballon";
import FontSize from "./font-size";
import DoubleGreaterThan from "./double-greater-than";
import DoubleLowerThan from "./double-lower-than";
import Text from "./text";
import Video from "./video";
import ArrowLeftCircle from "./arrow-left-circle";
import ArrowRightCircle from "./arrow-right-circle";
import PlayVideo from "./play-video";
import PauseVideo from "./pause-video";
import Volume from "./volume";
import VolumeMuted from "./volume-muted";
import Fullscreen from "./fullscreen";
import Subtitle from "./subtitle";
import PlaybackRate from "./playback-rate";
import More from "./more";
import PersonDocument from "./person-document";
import Upload from "./upload";
import Camera from "./camera";
import PaperCertificate from "./paper-certificate";
import InfoCircle from "./info-circle";
import Organogram from "./organogram";
import PlaceFilled from "./place-filled";
import Lightning from "./lightning";
import Trash from "./trash";
import Help from "./help";
import CircleBarcode from "./circle-barcode";
import HighlightedList from "./highlighted-list";
import PercentageBarcode from "./percentage-barcode";
import Wallet from "./wallet";
import Copy from "./copy";
import Printer from "./printer";
import Share from "./share";
import Recurring from "./recurring";
import CheckboxOverflow from "./checkbox-overflow";
import Leave from "./leave";
import ClockAlt from "./clock-alt";
import PointUp from "./point-up";
import Refresh from "./refresh";
import Trophy from "./trophy";
import SadBook from "./sad-book";
import NoAttachments from "./no-attachments";
import CheckedCircle from "./checked-circle";
import OpenBook from "./open-book";
import Menu from "./menu";
import ChevronRight from "./chevron-right";
import CertificateHorizontal from "./certificate-horizontal";
import GooglePlay from "./google-play";
import AppleStore from "./apple-store";
import SecuritySite from "./security-site";
import BookOpen from "./book-open";
import Calculator from "./calculator";
import Tasks from "./tasks";
import ThumbsUp from "./thumbs-up";
import Smile from "./smile";
import PhoneStar from "./phone-star";
import Search from "./search";
import ChevronLeft from "./chevron-left";
import WhatsappOutline from "./whatsapp-outline";
import YoutubeOutline from "./youtube-outline";
import LinkedinOutline from "./linkedin-outline";
import TwitterOutline from "./twitter-outline";
import StarOutline from "./star-outline";
import Attendant from "./attendant";
import LaptopChat from "./laptop-chat";
import LaptopSmartphone from "./laptop-smartphone";
import PigBank from "./pig-bank";
import ThumbsUpBalloon from "./thumbs-up-balloon";
import Notes from "./notes";
import PlayCircle from "./play-circle";
import DoubleChevron from "./double-chevron";
import PlusIcon from "./plus-icon";
import HoldingPhone from "./holding-phone";
import ReceivingMessages from "./receiving-messages";
import WatchingVideo from "./watching-video";
import Coupon from "./coupon";
import Add from "./add";
import Lock from "./lock";
import Timer from "./timer";
import CheckCircleAlt from "./check-circle-alt";
import MenuBook from "./menu-book";
import RateStar from "./rate-star";
import BoldCheck from "./bold-check";
import NoHatGraduation from "./no-hat-graduation";
import LeftArrow from "./left-arrow";
import PlayDisabled from "./play-disabled";
import Cobweb from "./cobweb";
export var Icon = {
  Calendar: Calendar,
  Cobweb: Cobweb,
  Speed: Speed,
  PhonePlay: PhonePlay,
  Message: Message,
  HatGraduation: HatGraduation,
  Check: Check,
  Facebook: Facebook,
  Instagram: Instagram,
  LinkedIn: LinkedIn,
  Mail: Mail,
  Phone: Phone,
  MapPin: MapPin,
  List: List,
  X: X,
  AlertCircle: AlertCircle,
  AlertCircleBold: AlertCircleBold,
  CheckCircle: CheckCircle,
  Radio: Radio,
  InfoCircleBold: InfoCircleBold,
  Checkbox: Checkbox,
  Clock: Clock,
  MessageCircle: MessageCircle,
  CalendarSimple: CalendarSimple,
  Star: Star,
  User: User,
  Home: Home,
  Paper: Paper,
  School: School,
  Whatsapp: Whatsapp,
  Document: Document,
  UncheckedCircle: UncheckedCircle,
  DoNotDisturb: DoNotDisturb,
  Iphone: Iphone,
  Heart: Heart,
  Write: Write,
  Vector: Vector,
  Place: Place,
  Chat: Chat,
  Wifi: Wifi,
  Library: Library,
  Money: Money,
  Bell: Bell,
  SmartPhone: SmartPhone,
  DownloadCloud: DownloadCloud,
  Award: Award,
  Certificate: Certificate,
  ArrowDown: ArrowDown,
  ArrowUp: ArrowUp,
  SeatedWoman: SeatedWoman,
  SeatedWomanBig: SeatedWomanBig,
  ArrowLeft: ArrowLeft,
  Dot: Dot,
  Eye: Eye,
  Folder: Folder,
  DollarSign: DollarSign,
  SpeechBalloon: SpeechBalloon,
  GreaterThan: GreaterThan,
  LowerThan: LowerThan,
  ArrowLineRight: ArrowLineRight,
  ArrowRight: ArrowRight,
  PersonReading: PersonReading,
  Pen: Pen,
  FileText: FileText,
  Pdf: Pdf,
  ArrowDownCircle: ArrowDownCircle,
  Objectives: Objectives,
  Introduction: Introduction,
  Content: Content,
  One: One,
  Two: Two,
  Three: Three,
  Four: Four,
  Five: Five,
  Six: Six,
  HelpBallon: HelpBallon,
  FontSize: FontSize,
  DoubleLowerThan: DoubleLowerThan,
  DoubleGreaterThan: DoubleGreaterThan,
  Text: Text,
  Video: Video,
  ArrowRightCircle: ArrowRightCircle,
  ArrowLeftCircle: ArrowLeftCircle,
  PlayVideo: PlayVideo,
  PauseVideo: PauseVideo,
  Volume: Volume,
  VolumeMuted: VolumeMuted,
  Fullscreen: Fullscreen,
  Subtitle: Subtitle,
  PlaybackRate: PlaybackRate,
  More: More,
  PersonDocument: PersonDocument,
  Upload: Upload,
  Camera: Camera,
  PaperCertificate: PaperCertificate,
  InfoCircle: InfoCircle,
  Organogram: Organogram,
  PlaceFilled: PlaceFilled,
  Lightning: Lightning,
  Trash: Trash,
  Help: Help,
  CircleBarcode: CircleBarcode,
  HighlightedList: HighlightedList,
  PercentageBarcode: PercentageBarcode,
  Wallet: Wallet,
  Copy: Copy,
  Printer: Printer,
  Share: Share,
  Recurring: Recurring,
  CheckboxOverflow: CheckboxOverflow,
  Leave: Leave,
  ClockAlt: ClockAlt,
  PointUp: PointUp,
  Refresh: Refresh,
  Trophy: Trophy,
  SadBook: SadBook,
  NoAttachments: NoAttachments,
  CheckedCircle: CheckedCircle,
  OpenBook: OpenBook,
  Menu: Menu,
  ChevronRight: ChevronRight,
  CertificateHorizontal: CertificateHorizontal,
  GooglePlay: GooglePlay,
  AppleStore: AppleStore,
  SecuritySite: SecuritySite,
  BookOpen: BookOpen,
  Calculator: Calculator,
  Tasks: Tasks,
  ThumbsUp: ThumbsUp,
  Smile: Smile,
  PhoneStar: PhoneStar,
  Search: Search,
  ChevronLeft: ChevronLeft,
  WhatsappOutline: WhatsappOutline,
  YoutubeOutline: YoutubeOutline,
  LinkedinOutline: LinkedinOutline,
  TwitterOutline: TwitterOutline,
  StarOutline: StarOutline,
  Attendant: Attendant,
  LaptopChat: LaptopChat,
  LaptopSmartphone: LaptopSmartphone,
  PigBank: PigBank,
  ThumbsUpBalloon: ThumbsUpBalloon,
  Notes: Notes,
  PlayCircle: PlayCircle,
  DoubleChevron: DoubleChevron,
  PlusIcon: PlusIcon,
  HoldingPhone: HoldingPhone,
  ReceivingMessages: ReceivingMessages,
  WatchingVideo: WatchingVideo,
  Coupon: Coupon,
  Add: Add,
  Lock: Lock,
  Timer: Timer,
  CheckCircleAlt: CheckCircleAlt,
  MenuBook: MenuBook,
  RateStar: RateStar,
  BoldCheck: BoldCheck,
  NoHatGraduation: NoHatGraduation,
  LeftArrow: LeftArrow,
  PlayDisabled: PlayDisabled
};