import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import { css } from '@emotion/css';
import { px2rem, getColor, mediaQuery } from "../../utils";
export var timerComponentContent = function timerComponentContent(onClick) {
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  background: ", ";\n  display: flex;\n  justify-content: center;\n  flex-direction: row;\n  align-items: center;\n  height: ", ";\n  ", "\n  ", " {\n    flex-direction: column;\n    height: auto;\n  }\n"])), getColor('semantic-alert'), px2rem(60), onClick && 'cursor: pointer;', mediaQuery('mobile'));
};
export var timerContent = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: ", " ", ";\n  ", " {\n    margin: ", " ", ";\n  }\n"])), px2rem(8), px2rem(14), mediaQuery('mobile'), px2rem(0), px2rem(14));
export var timeText = function timeText(fontSize) {
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-weight: 400;\n  text-align: center;\n  color: black;\n  height: ", ";\n  font-size: ", ";\n"])), px2rem(22), fontSize);
};
export var timeTitle = css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-weight: 400;\n  text-align: center;\n  color: black;\n  font-size: ", ";\n  margin: ", " 0px;\n  ", " {\n    margin: ", " 0px 0px 0px;\n  }\n"])), px2rem(20), px2rem(12), mediaQuery('mobile'), px2rem(8));
export var timeContent = css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  ", " {\n    margin-bottom: ", ";\n  }\n"])), mediaQuery('mobile'), px2rem(8));
export var seeMoreButton = css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-weight: 700;\n  font-size ", ";\n  color: black;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-left: ", ";\n  border: 1px solid black;\n  border-radius: 4px;\n  padding: ", " ", " ", " ", ";\n  svg {\n    width: ", ";\n    height: ", ";\n  }\n\n  ", " {\n    display: none;\n  }\n"])), px2rem(16), px2rem(10), px2rem(6), px2rem(8), px2rem(6), px2rem(12), px2rem(20), px2rem(20), mediaQuery('mobile'));