/* eslint-disable  */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  css,
  cx,
  px2grid,
  px2rem,
  ColorBrandBase,
  ColorNeutralDark,
  ColorNeutralLighter,
} from '@ampli/ui';

import { HEADER_LINKS } from '../../constants';

const dropdownItemStyle = cx(
  'dropdown-item-link',
  css`
    color: ${ColorNeutralDark};
    font-weight: 400;
    text-decoration: none;
    padding: ${px2grid(5)};
    &:hover {
      color: ${ColorBrandBase};
      text-decoration: none;
    }
  `
);

const DropdownMenu = () => {
  const dropdownItems = [
    { label: 'graduação', href: '/graduacao' },
    { label: 'pós-graduação', href: '/pos-graduacao' },
    {
      label: 'eja',
      href: '/cursos/eja',
    },
    {
      label: 'cursos livres',
      href: HEADER_LINKS['cursosLivres'],
      target: '_blank',
    },
    {
      label: 'curso técnico',
      href: '/cursos/eja',
    },
  ];

  return (
    <ul
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={cx(
        'dropdown-items',
        css`
          position: absolute;
          text-align: center;
          list-style-type: none;
          background-color: #ffffff;
          margin-right: 10%;
          width: ${px2grid(200)};
          height: ${px2grid(230)};
          padding: ${px2grid(12)};
          position: absolute;
          border-radius: 0.3em;
        `
      )}
    >
      {dropdownItems.map((item, index) => {
        return (
          <li
            key={index}
            className={cx(
              'item',
              css`
                border-bottom: ${px2rem(1)} solid ${ColorNeutralLighter};
                padding: 0.5em;
              `
            )}
          >
            {item.icon}
            {item.target ? (
              <a
                className={dropdownItemStyle}
                href={item.href}
                target="_blank"
                rel="noreferrer"
              >
                {item.label}
              </a>
            ) : (
              <Link className={dropdownItemStyle} to={item.href}>
                {item.label}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default DropdownMenu;
