import React from 'react';

const SvgDottedLine = (props) => {
  return (
    <svg
      width="436"
      height="3"
      viewBox="0 0 436 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="1"
        y1="1.97144"
        x2="435"
        y2="1.97144"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="8 8"
      />
    </svg>
  );
};

export default SvgDottedLine;
