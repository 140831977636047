import React from 'react';
import { asyncComponent } from '@jaredpalmer/after';
import Home from '../pages/Home';
import { Polos } from '../pages/Polos';
import {
  SubscriptionPageOne,
  SubscriptionPageTwo,
  SubscriptionPageThree,
} from '../pages/Subscription';
import { UnifiedRedirect } from '../components';
import {
  generateSitemapByCategoryUrlCode,
  generateSitemapByURLCode,
} from '../lib/sitemap-generation';
const courseListLoader = () =>
  import(/* webpackChunkName: "CourseListAedu" */ '../pages/CourseList');
const CourseListAeduLoader = () =>
  import(/* webpackChunkName: "CourseListAedu" */ '../pages/CourseListAedu');

const generateSitemapForCourseDetails = (degree) => () =>
  generateSitemapByURLCode(degree);

const generateSitemapForCourseListCategory = (degree) => () =>
  generateSitemapByCategoryUrlCode(degree);

export default [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/cursos',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "Courses" */ '../pages/Courses'),
      chunkName: 'Courses',
    }),
  },
  {
    path: '/graduacao',
    exact: true,
    component: asyncComponent({
      loader: courseListLoader,
      chunkName: 'CourseList',
    }),
  },
  {
    path: '/pos-graduacao',
    exact: true,
    component: asyncComponent({
      loader: courseListLoader,
      chunkName: 'CourseList',
    }),
  },
  {
    path: '/cursos/eja',
    exact: true,
    component: asyncComponent({
      loader: CourseListAeduLoader,
      chunkName: 'CourseListAedu',
    }),
  },
  {
    path: '/cursos/eja/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: CourseListAeduLoader,
      chunkName: 'CourseListAedu',
    }),
    sitemap: {
      urlCode: generateSitemapForCourseListCategory('EJA'),
    },
  },
  // {
  //   path: '/tecnico',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: CourseListAeduLoader,
  //     chunkName: 'CourseListAedu',
  //   }),
  // },
  {
    path: '/graduacao/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsUndergraduate" */ '../pages/CourseDetailsUndergraduate'
        ),
      chunkName: 'CourseDetailsUndergraduate',
    }),
    sitemap: {
      urlCode: generateSitemapForCourseDetails('UNDERGRADUATE'),
    },
  },
  {
    path: '/pos-graduacao/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsGraduate" */ '../pages/CourseDetailsGraduate'
        ),
      chunkName: 'CourseDetailsGraduate',
    }),
    sitemap: {
      urlCode: generateSitemapForCourseDetails('GRADUATE'),
    },
  },
  {
    path: '/eja/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsEJA" */ '../pages/CourseDetailsEJA'
        ),
      chunkName: 'CourseDetailsEJA',
    }),
    sitemap: {
      urlCode: generateSitemapForCourseDetails('EJA'),
    },
  },
  // {
  //   path: '/tecnico/:urlCode',
  //   exact: true,
  //   component: asyncComponent({
  //     loader: () =>
  //       import(
  //         /* webpackChunkName: "CourseDetailsTechnical" */ '../pages/CourseDetailsTechnical'
  //       ),
  //     chunkName: 'CourseDetailsTechnical',
  //   }),
  //   sitemap: {
  //     urlCode: generateSitemapForCourseDetails('TECHNICAL'),
  //   },
  // },
  {
    path: '/tim',
    exact: true,
    // eslint-disable-next-line react/display-name
    component: () => (
      <UnifiedRedirect pathname="/parceria/tim" preserveQuerystrings={true} />
    ),
    redirect: true,
    sitemap: false,
  },
  {
    path: '/parceria',
    exact: true,
    // eslint-disable-next-line react/display-name
    component: () => (
      <UnifiedRedirect pathname="/parceria/tim" preserveQuerystrings={true} />
    ),
    redirect: true,
  },
  {
    path: '/parceria/tim',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Tim" */ '../pages/Tim'),
      chunkName: 'Tim',
    }),
    sitemap: false,
  },
  {
    path: '/parceria/tim-vendedor',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "TimSeller" */ '../pages/TimSeller'),
      chunkName: 'TimSeller',
    }),
    sitemap: false,
  },
  {
    path: '/polos-ead/:courseDegree',
    exact: true,
    component: asyncComponent({
      loader: () => import('../pages/PoloCourses'),
      chunkName: 'Polos',
    }),
    sitemap: {
      courseDegree: () => Promise.resolve(['eja', 'graduacao']),
    },
  },
  {
    path: '/polos-ead',
    exact: true,
    component: Polos,
  },
  {
    path: '/inscricao/cadastro',
    exact: true,
    component: SubscriptionPageOne,
    sitemap: false,
  },
  {
    path: '/inscricao/curso',
    exact: true,
    component: SubscriptionPageTwo,
    sitemap: false,
  },
  {
    path: '/inscricao/dados-pessoais',
    exact: true,
    component: SubscriptionPageThree,
    sitemap: false,
  },
];
