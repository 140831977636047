import { baseUrl, isLocal } from '../config';

import { useCheckDateIsInPeriod } from '@ampli/hooks';

import {
  AMPLI_BLACK_WEEK_START_DATE,
  AMPLI_BLACK_WEEK_END_DATE,
} from '../constants';

function useCheckBlackWeekDate() {
  const { isValidDate: isAmpliBlackWeek } = useCheckDateIsInPeriod(
    AMPLI_BLACK_WEEK_START_DATE,
    AMPLI_BLACK_WEEK_END_DATE,
    (isLocal ? 'http://localhost:7005' : baseUrl) + '/time'
  );

  return isAmpliBlackWeek;
}

export default useCheckBlackWeekDate;
