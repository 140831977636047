import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import { getColor, css, px2grid } from '@ampli/ui';
var neutralTitleColor = getColor('neutral-title');
var labelFontFamily = 'Space Grotesk, sans-serif';
export var labelStyle = {
  labelWeight: 700,
  labelColor: neutralTitleColor,
  labelFontFamily: labelFontFamily
};
export var labelNormalFontStyle = {
  labelWeight: 700,
  labelColor: neutralTitleColor
};
export var specialNeedsLegend = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-top: ", ";\n  font-weight: 700;\n  color: ", ";\n"])), px2grid(15), neutralTitleColor);