import React, { ReactNode, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { cx, Text, Flex, GradientButton, BrandedCard } from '@ampli/ui';
import { string, node, bool } from '@ampli/utils';
import styles from './styles';

import Illustrations from './illustrations';

interface DegreeCardProps {
  illustration: keyof typeof Illustrations;
  title: string;
  subtitle?: string;
  children?: ReactNode;
  link: string;
  openLinkInNewTab?: boolean;
  className?: string;
  externalLink?: boolean;
}

const DegreeCard: FunctionComponent<DegreeCardProps> = ({
  illustration,
  title,
  subtitle,
  children,
  link,
  openLinkInNewTab = false,
  externalLink = false,
  className,
  ...props
}: DegreeCardProps) => {
  const Illustration = Illustrations[illustration];

  return (
    <BrandedCard
      maxWidth={340}
      height={325}
      className={cx('degree-card', styles.degreeCard, className)}
      {...props}
    >
      <Flex
        direction="column"
        alignItems="flex-end"
        justifyContent="space-around"
      >
        <div className="card-content">
          {illustration && <Illustration className="illustration" />}
          <Text
            as="h2"
            className="title-wrapper"
            aria-labelledby="title subtitle"
          >
            <Text
              id="title"
              color="semantic-alert"
              size="huge"
              className="title"
              block
            >
              {title}
            </Text>
            {subtitle && (
              <Text
                id="subtitle"
                color="semantic-alert"
                size="x-large"
                className="subtitle"
              >
                {subtitle}
              </Text>
            )}
          </Text>

          <Text color="neutral-dark" className="description">
            {children}
          </Text>
          <GradientButton
            as={externalLink ? 'a' : Link}
            to={link}
            href={link}
            size="micro"
            target={openLinkInNewTab && '_'}
          >
            Conheça
          </GradientButton>
        </div>
      </Flex>
    </BrandedCard>
  );
};

DegreeCard.propTypes = {
  illustration: string,
  title: string,
  subtitle: string,
  children: node,
  link: string,
  openLinkInNewTab: bool,
  className: string,
  externalLink: bool,
};

export default DegreeCard;
