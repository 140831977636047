import React, { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';

import { css, cx, Paragraph, Text, Flex, Separator } from '@ampli/ui';
import {
  SecuritySite as SecureSiteBadge,
  GooglePlay as GooglePlayIcon,
  AppleStore as AppleStoreIcon,
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  TwitterOutline as TwitterOutlineIcon,
  LinkedinOutline as LinkedinOutlineIcon,
  YoutubeOutline as YoutubeOutlineIcon,
  Mail as MailIcon,
} from '@ampli/icon';
import { string, node, elementType } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

import { AmpliLogo, CognaLogo, AnhangueraLogo } from './logos';
import CNPJInformation from './cnpj-information';
import LegalDocuments from './legal-documents';
import FooterSEO from './footer-seo';
import QRCodeMec from './qrcode-mec';
import { STORES, FOOTER_SEO_CATEGORIES } from '../../constants';
import {
  mediaSection,
  footerSection,
  wrapStyle,
  footerStyle,
  logoListStyle,
  socialListStyle,
  storesListStyle,
  socialItemStyle,
  mediaIconStyle,
  mediaSectionStyle,
  footerSeparatorStyle,
  socialWrapperStyle,
  securityItemStyle,
  storesItemStyle,
  logoSeparatorStyle,
  logoItemStyle,
  footerSeparatorDesktopStyle,
  storesLinkStyle,
} from './styles';

const Wrap = (props) => (
  <div {...props} className={cx('wrap', wrapStyle, props.className)} />
);
Wrap.propTypes = {
  className: string,
};

const Footer = forwardRef(({ children, ...props }, ref) => (
  <footer
    {...props}
    ref={ref}
    className={cx('footer', footerStyle, props.className)}
  >
    <Wrap>{children}</Wrap>
  </footer>
));
Footer.propTypes = {
  className: string,
  children: node,
};
Footer.displayName = 'Footer';

const LogoList = (props) => (
  <ul {...props} className={cx('logo-list', logoListStyle, props.className)} />
);
LogoList.propTypes = {
  className: string,
};

const LogoItem = (props) => (
  <li {...props} className={cx('logo-item', css``, props.className)} />
);
LogoItem.propTypes = {
  className: string,
};

const SocialList = (props) => (
  <ul
    {...props}
    className={cx('social-list', socialListStyle, props.className)}
  />
);
SocialList.propTypes = {
  className: string,
};

const StoresList = (props) => (
  <ul
    {...props}
    className={cx('stores-list', storesListStyle, props.className)}
  />
);
StoresList.propTypes = {
  className: string,
};

const SocialItem = (props) => (
  <li
    {...props}
    className={cx('social-item', socialItemStyle, props.className)}
  />
);
SocialItem.propTypes = {
  className: string,
};

const SocialIcon = ({ media, ...props }) => {
  const MediaIcon = media;
  return (
    <MediaIcon
      {...props}
      title={`Siga a Ampli no ${media}`}
      className={cx(`${media}-icon`, mediaIconStyle, props.className)}
    />
  );
};
SocialIcon.propTypes = {
  className: string,
  media: elementType.isRequired,
};

const FooterSection = (props) => (
  <div
    {...props}
    className={cx('footer-section', footerSection, props.className)}
  />
);
FooterSection.propTypes = {
  className: string,
};

const MediaSection = (props) => (
  <div
    {...props}
    className={cx('media-section', mediaSectionStyle, props.className)}
  />
);
MediaSection.propTypes = {
  className: string,
};

const FooterSeparator = (props) => (
  <hr
    {...props}
    className={cx('footer-separator', footerSeparatorStyle, props.className)}
  />
);
FooterSeparator.propTypes = {
  className: string,
};

const SecurityItem = (props) => (
  <div
    {...props}
    className={cx('security-item', securityItemStyle, props.className)}
  />
);
SecurityItem.propTypes = {
  className: string,
};

const StoresItem = (props) => (
  <li
    {...props}
    className={cx('stores-item', storesItemStyle, props.className)}
  />
);
StoresItem.propTypes = {
  className: string,
};

const SecuritySiteIcon = (props) => (
  <SecureSiteBadge
    {...props}
    className={cx('security-site', css``, props.className)}
  />
);

SecuritySiteIcon.propTypes = {
  className: string,
};

const SocialWrapper = (props) => (
  <div
    {...props}
    className={cx('social-wrapper', socialWrapperStyle, props.className)}
  />
);

SocialWrapper.propTypes = {
  className: string,
};

const LogoSeparator = () => (
  <Separator
    orientation="vertical"
    color="background-translucent"
    className={cx('logo-separator', logoSeparatorStyle)}
  />
);

const MainFooter = forwardRef((props, ref) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';

  const query = new URLSearchParams(useLocation().search);
  const anhanguera = query.get('utm_campaign');
  const isAnhanguera = anhanguera?.includes('Anhanguera');

  const orderInstitutionalSection = (items) => {
    items.map((item) => {
      if (item.title === 'Institucional')
        item.items.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
    });
    return items;
  };

  const socialMediaArray = [
    { media: InstagramIcon, link: 'https://www.instagram.com/amplioficial/' },
    { media: FacebookIcon, link: 'https://www.facebook.com/OficialAmpli' },
    {
      media: LinkedinOutlineIcon,
      link: 'https://www.linkedin.com/school/amplioficial',
    },
    {
      media: YoutubeOutlineIcon,
      link: 'https://www.youtube.com/channel/UCQuBgGO1XDvn9HmDwW9c-Hw',
    },
    {
      media: MailIcon,
      link: 'mailto:contato@ampli.com.br',
    },
    {
      media: TwitterOutlineIcon,
      link: 'https://twitter.com/AmpliOficial',
    },
  ];

  return (
    <Footer ref={ref} id="footer">
      <FooterSection>
        <FooterSEO
          categories={orderInstitutionalSection(FOOTER_SEO_CATEGORIES)}
        />
        {!isDesktop && (
          <SecurityItem>
            <SecuritySiteIcon />
          </SecurityItem>
        )}
        <LogoList>
          <LogoItem>
            <a href="/">
              <AmpliLogo />
            </a>
          </LogoItem>
          <LogoSeparator />
          <LogoItem>
            <a
              href="http://www.cogna.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CognaLogo />
            </a>
          </LogoItem>
          <LogoItem className={logoItemStyle(isDesktop)}>
            <SecurityItem>
              <SecuritySiteIcon />
            </SecurityItem>
          </LogoItem>
          {isAnhanguera && (
            <>
              <LogoSeparator />
              <LogoItem>
                <AnhangueraLogo />
              </LogoItem>
            </>
          )}
        </LogoList>
        {!isDesktop ? <QRCodeMec /> : null}
        <MediaSection>
          <div className={mediaSection}>
            <div>
              <SocialWrapper>
                <Paragraph marginSize="x-small">
                  <Text color="text-white" size="small">
                    Fale com a gente
                  </Text>
                </Paragraph>
                <SocialList>
                  {socialMediaArray.map((socialMedia) => {
                    return (
                      <SocialItem key={socialMedia}>
                        <a href={socialMedia.link}>
                          <SocialIcon media={socialMedia.media} />
                        </a>
                      </SocialItem>
                    );
                  })}
                </SocialList>
              </SocialWrapper>
              <StoresList>
                <StoresItem>
                  <Paragraph
                    marginSize="x-small"
                    textAlign={isDesktop ? 'left' : 'center'}
                  >
                    <Text color="text-white" size="small">
                      Disponível na
                    </Text>
                  </Paragraph>
                  <Flex direction="row">
                    <div>
                      <a
                        href={STORES.GOOGLEPLAY.URL}
                        className={storesLinkStyle}
                      >
                        <GooglePlayIcon />
                      </a>
                      <a href={STORES.APPLESTORE.URL}>
                        <AppleStoreIcon />
                      </a>
                    </div>
                  </Flex>
                </StoresItem>
              </StoresList>
            </div>
            {isDesktop ? <QRCodeMec /> : null}
          </div>
        </MediaSection>
        <FooterSeparator className={footerSeparatorDesktopStyle} />
        <LegalDocuments />
      </FooterSection>
      <CNPJInformation />
    </Footer>
  );
});
MainFooter.displayName = 'MainFooter';

export default MainFooter;
