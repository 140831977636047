import React from 'react';
import { useScroller } from '@ampli/hooks';
import { AppShell, Helmet, Breadcrumbs } from '../../components';
import { Modal, useSubscriptionModal } from '@ampli/registration';
import { SCROLLER_SETTINGS } from '../../constants';
import { cx, getColor } from '@ampli/ui';
import { ArrowLineRight } from '@ampli/icon';
import { RunningIllustration } from '../../assets/illustration';
import { EjaFlagsResponseInterface } from '../../interfaces';
import {
  ampli,
  entranceExamUrl,
  recaptchaSiteKey,
  googleAPIKey,
} from '../../config';
import { useFetch } from '../../hooks';
import styles from './styles';

interface CourseButtonsProps {
  courseDegree: 'EJA' | 'Graduação';
}
const CoursesButtons = ({ courseDegree }: CourseButtonsProps) => {
  const goToPoloCoursePage = () => {
    location.href = `/polos-ead/${
      courseDegree === 'EJA' ? 'eja' : 'graduacao'
    }`;
  };

  return (
    <button
      onClick={goToPoloCoursePage}
      className={cx('polo-button-course', styles.poloButtonCourse)}
    >
      <p className={cx('polo-button-text', styles.poloButtonText)}>
        {courseDegree}
      </p>
      <ArrowLineRight
        className={cx('polo-button-arrow', styles.poloButtonArrow)}
      />
    </button>
  );
};

const Polo = () => {
  const flagsResponse: EjaFlagsResponseInterface = useFetch('/flags.json');
  const { scroller: homeScroller } = useScroller(SCROLLER_SETTINGS);
  const subscriptionModal = useSubscriptionModal();

  const breadcrumbItems = [
    {
      label: 'Página inicial',
      url: '/',
    },
    {
      label: 'Polos EAD',
      url: `/polos-ead`,
    },
  ];

  return (
    <>
      <Helmet
        title="Veja o endereço do Polo EAD da Ampli mais próximo de você"
        description="Confira o endereço do Polo EAD da Ampli mais próximo de você. São mais de 500 núcleos de apoio espalhados por todo o Brasil. Saiba mais."
        robots="robots"
      />
      <AppShell
        headerConfig={{
          pageTopScroller: homeScroller,
          contactScroller: homeScroller,
          onSubscribeClick: subscriptionModal.show,
        }}
      >
        <Breadcrumbs
          items={breadcrumbItems}
          color={`${getColor('neutral-dark')}`}
          className={styles.poloRootBreadcrumbs}
        />
        <div className={cx('polos-content', styles.poloContent)}>
          <RunningIllustration
            className={cx('polos-illustration', styles.poloIllustrations)}
          />
          <div
            className={cx('polos-buttons-content', styles.poloButtonContent)}
          >
            <h1 className={cx('polo-title', styles.poloTitle)}>Polos Ampli</h1>
            <CoursesButtons courseDegree="Graduação" />
            <CoursesButtons courseDegree="EJA" />
          </div>
        </div>
      </AppShell>
      <Modal
        {...subscriptionModal}
        ampli={ampli}
        entranceExamUrl={entranceExamUrl}
        formSettings={{ recaptchaSiteKey, googleAPIKey }}
        isElementaryEjaEnable={flagsResponse?.isElementaryEjaEnable}
        isHighSchoolEjaEnable={flagsResponse?.isHighSchoolEjaEnable}
      />
    </>
  );
};

export default Polo;
