import { pathUrlField } from './commons';
var type = {
  read: function read(cached, _ref) {
    var readField = _ref.readField;
    if (cached) return cached;
    var firstAssetType = 'text';
    var hasVideo = false;
    var assets = readField('assets');

    if ((assets === null || assets === void 0 ? void 0 : assets.length) > 0) {
      var _assets$filter;

      var asset = assets[0];
      firstAssetType = readField('type', asset).replace('Asset', '').toLowerCase();
      hasVideo = ((_assets$filter = assets.filter(function (asset) {
        return readField('type', asset).toLowerCase().includes('video');
      })) === null || _assets$filter === void 0 ? void 0 : _assets$filter.length) > 0;
    }

    return firstAssetType === 'image' && !hasVideo ? 'text' : hasVideo ? 'video' : firstAssetType;
  }
};
export default {
  fields: {
    type: type,
    pathUrl: pathUrlField
  }
};