import React from 'react';

import {
  cx,
  css,
  Text,
  boxShadow,
  px2grid,
  getSpaceSize,
  ColorBrandBase,
  ColorSemanticAlert,
} from '@ampli/ui';
import { Check as CheckIcon } from '@ampli/icon';
import { useBreakpoint } from '@ampli/hooks';
import { elementType, node } from '@ampli/utils';
import { MIN_WIDTH_DESKTOP } from './why-ampli';
import { IconSelector } from '../';

const CardInfo = ({ children, icon }) => {
  const breakpoint = useBreakpoint(true);

  const isDesktop = React.useMemo(
    () =>
      breakpoint?.isDesktop &&
      breakpoint?.currentSize?.width >= MIN_WIDTH_DESKTOP,
    [breakpoint]
  );

  const resolvedIcon = icon && (
    <IconSelector icon={icon} size={50} color="semantic-alert" />
  );
  return (
    <div
      className={cx(
        'why-ampli-card-wrap',
        css`
          display: flex;
          flex-direction: column;
          border-radius: ${px2grid(8)};
          background-color: white;
          padding: ${getSpaceSize('xx-small')};
          margin-bottom: ${getSpaceSize('large')};
          width: ${px2grid(340)};
          height: ${px2grid(106)};
          @media (min-width: ${MIN_WIDTH_DESKTOP}px) {
            width: ${px2grid(300)};
            height: ${px2grid(180)};
          }
        `,
        boxShadow('card')
      )}
    >
      {isDesktop && (
        <div
          className={cx(
            'check-icon-wrap',
            css`
              display: flex;
              justify-content: flex-end;
            `
          )}
        >
          <div
            className={cx(
              'check-icon-content',
              css`
                background-color: ${ColorBrandBase};
                padding: ${px2grid(4)};
                display: flex;
                width: fit-content;
                height: fit-content;
                align-items: center;
                justify-content: center;
                border-radius: ${px2grid(16)};
              `
            )}
          >
            <CheckIcon color="white" />
          </div>
        </div>
      )}

      <div
        className={cx(
          'why-ampli-card-content',
          css`
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
          `
        )}
      >
        <Text
          className={cx(
            'why-ampli-card-icon',
            css`
              width: ${px2grid(130)};
              height: ${px2grid(50)};
              text-align: center;
              margin-right: ${px2grid(16)};
            `
          )}
          color={ColorSemanticAlert}
        >
          {resolvedIcon}
        </Text>

        <div
          className={cx(
            'card-content-text-wrap',
            css`
              max-width: ${px2grid(180)};
            `
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

CardInfo.propTypes = {
  children: node,
  icon: elementType,
};

export default CardInfo;
