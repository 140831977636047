import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useHideMundialeChatbot } from '@ampli/hooks';
import { SubscriptionThirdStep } from '@ampli/registration';
import {
  Header,
  SubscriptionContent,
  Helmet,
  BannerHeader,
} from '../../components';
import { CONTACT, STORAGE_SUBSCRIPTION_FORM } from '../../constants';
import {
  recaptchaSiteKey,
  googleAPIKey,
  ampli,
  entranceExamUrl,
} from '../../config';
import { authStorage } from '@ampli/utils';
import { Text } from '@ampli/ui';
import { useServiceClient } from '@ampli/services';

const SubscriptionPageThree: React.FC = () => {
  useHideMundialeChatbot();
  const client = useServiceClient();
  const history = useHistory();
  const [isFreeTrialUser, setIsFreeTrialUser] = useState<boolean>(false);
  const currentStep = 2;

  const candidateAdditionalData =
    typeof window !== 'undefined' &&
    window.localStorage &&
    JSON.parse(
      window.localStorage.getItem(STORAGE_SUBSCRIPTION_FORM) as string
    );

  const courseSelected =
    typeof window !== 'undefined' &&
    sessionStorage.getItem('selectedCourseToSubscribe');

  const courseDataParamSelected =
    typeof window !== 'undefined' &&
    sessionStorage.getItem('courseParamDataToSubscribe');

  const candidateData =
    candidateAdditionalData || courseSelected || courseDataParamSelected;

  const checkIfIsFreeTrialUser = () => {
    const freeTrialFlag = sessionStorage.getItem('isFreeTrialUser');
    setIsFreeTrialUser(freeTrialFlag === 'true');
  };

  useEffect(() => {
    checkIfIsFreeTrialUser();
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem('subscriptionSecondStep')) {
      history.push('/inscricao/cadastro');
    }
  }, [history]);

  const onFinishCandidateFlow = () => {
    authStorage
      .getItem('token')
      .then(async (token) => {
        await authStorage.removeItem('token');
        const candidateId = await authStorage.getItem('candidateId');
        window.location.href = `${entranceExamUrl}/login/${token}/${candidateId}`;
        return true;
      })
      .catch(console.error);
  };

  return (
    <div>
      <Helmet title="Inscrição Ampli" robots="noindex" />
      <BannerHeader />
      <Header
        isInscriptionPage={true}
        pageTopScroller={''}
        contactScroller={() => window?.open(CONTACT.WHATSAPP, '_blank')}
      />
      <SubscriptionContent isInscriptionThirdPage={true}>
        <Text
          fontWeight="700"
          size="36px"
          color="neutral-title"
          as="h1"
          marginSize="48px"
        >
          Dados complementares
        </Text>
        {candidateData && (
          <SubscriptionThirdStep
            candidateAdditionalData={candidateAdditionalData}
            client={client}
            ampli={ampli}
            entranceExamUrl={entranceExamUrl}
            formSettings={{ recaptchaSiteKey, googleAPIKey }}
            onSuccess={onFinishCandidateFlow}
            buttonText={'Continuar'}
            isFreeTrialOption={isFreeTrialUser}
          />
        )}
      </SubscriptionContent>
    </div>
  );
};

export default SubscriptionPageThree;
