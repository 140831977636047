import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useHideMundialeChatbot } from '@ampli/hooks';
import { SubscriptionSecondStep } from '@ampli/registration';
import {
  Header,
  SubscriptionContent,
  Helmet,
  BannerHeader,
} from '../../components';
import { recaptchaSiteKey, googleAPIKey } from '../../config';
import { STORAGE_SUBSCRIPTION_FORM, CONTACT } from '../../constants';
import { Text } from '@ampli/ui';
import { updateSubscriptionCache } from '../../lib/subscription-functions';
import { useGetCourseOnURLParam } from '../../hooks';

const SubscriptionPageTwo: React.FC = () => {
  useHideMundialeChatbot();
  const history = useHistory();
  const [currentData, setCurrentData] = useState({});
  const currentStep = 1;

  const selectedCourse = useGetCourseOnURLParam();

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const currentDataArray = JSON.parse(
      localStorage.getItem(STORAGE_SUBSCRIPTION_FORM) as string
    );
    setCurrentData(currentDataArray);
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem('subscriptionFirstStep')) {
      history.push('/inscricao/cadastro');
    }
  }, [history]);

  const onCompleted = (data) => {
    sessionStorage.setItem('subscriptionSecondStep', 'completed');
    updateSubscriptionCache(currentStep, data);
    history.push('/inscricao/dados-pessoais');
  };

  return (
    <>
      <Helmet title="Inscrição Ampli" robots="noindex" />
      <BannerHeader />
      <Header
        isInscriptionPage={true}
        pageTopScroller={''}
        contactScroller={() => window?.open(CONTACT.WHATSAPP, '_blank')}
      />
      <SubscriptionContent>
        <Text fontWeight="700" size="36px" color="neutral-title" as="h1">
          Escolha seu curso
        </Text>

        <SubscriptionSecondStep
          formSettings={{ recaptchaSiteKey, googleAPIKey }}
          candidateAdditionalData={{
            ...currentData,
          }}
          onCompleted={onCompleted}
          buttonText="Continuar"
          selectedCourseCode={selectedCourse.course}
          selectedCourseDegree={selectedCourse.courseDegree}
        />
      </SubscriptionContent>
    </>
  );
};

export default SubscriptionPageTwo;
