import React, { FunctionComponent } from 'react';

import {
  css,
  cx,
  PrimaryButton,
  px2rem,
  px2grid,
  Text,
  GradientLinearBrand,
  IconButton,
  ColorBrandBase,
  getSpaceSize,
  Flex,
  FlexItem,
} from '@ampli/ui';
import {
  Document as DocumentIcon,
  HatGraduation as HatGraduationIcon,
} from '@ampli/icon';

import AmpliLogo from '../header-logo';
import MenuDesktop from '../menu-desktop';

import { HeaderProps } from './types';
import { OnClickFunction } from '../../../types';

interface LinkConfig {
  href: string;
  target?: string;
}
interface DesktopHeaderProps extends HeaderProps {
  items: { label: string; onClick: OnClickFunction }[];
  loginLinkConfig: LinkConfig;
  checkSubscriptionLinkConfig: LinkConfig;
  visibleSection: string;
  isInscriptionPage: boolean;
}

const DesktopHeader: FunctionComponent<DesktopHeaderProps> = ({
  items,
  loginLinkConfig,
  checkSubscriptionLinkConfig,
  onSubscribeClick,
  visibleSection,
  isInscriptionPage,
  className,
}: DesktopHeaderProps) => (
  <FlexItem flex={1}>
    <Flex direction="row" justifyContent="space-between" alignItems="center">
      <div className={className}>
        <div
          className={cx(
            'wrap-content',
            css`
              display: flex;
              flex-direction: row;
            `
          )}
        >
          <AmpliLogo />
          <MenuDesktop
            items={items}
            visibleSection={visibleSection}
            isInscriptionPage={isInscriptionPage}
          />
        </div>

        <div>
          <IconButton
            as="a"
            {...checkSubscriptionLinkConfig}
            icon={<DocumentIcon />}
            iconColor={ColorBrandBase}
            className={cx(
              'text-button-primary',
              css`
                color: ${ColorBrandBase};
                font-weight: 400;
                cursor: pointer;
                margin-right: ${getSpaceSize('small')};
                svg {
                  width: ${px2rem(16)};
                  height: ${px2rem(16)};
                }
                path {
                  fill: ${ColorBrandBase};
                }
              `
            )}
          >
            {'acompanhar inscrição'}
          </IconButton>

          <IconButton
            as="a"
            {...loginLinkConfig}
            icon={<HatGraduationIcon />}
            iconSize="x-large"
            iconColor={ColorBrandBase}
            className={cx(
              'text-button-primary',
              css`
                color: ${ColorBrandBase};
                font-weight: 400;
                cursor: pointer;
                margin-right: ${getSpaceSize('small')};
                path {
                  fill: ${ColorBrandBase};
                }
              `
            )}
          >
            {'sou aluno'}
          </IconButton>

          {!isInscriptionPage && (
            <PrimaryButton
              onClick={onSubscribeClick}
              as="strong"
              className={cx(
                'primary-button-navbar',
                css`
                  font-weight: 400;
                  cursor: pointer;
                  background: ${GradientLinearBrand};
                  border-color: transparent;
                `
              )}
              size="micro"
            >
              <Text
                color="white"
                size="base"
                className={cx(
                  'button-navbar-text',
                  css`
                    padding: 0 ${px2grid(12)};
                  `
                )}
              >
                {'Inscreva-se de graça'}
              </Text>
            </PrimaryButton>
          )}
        </div>
      </div>
    </Flex>
  </FlexItem>
);

DesktopHeader.displayName = 'DesktopHeader';

export default DesktopHeader;
