import React from 'react';

import { cx, css, Text, px2grid, mediaQuery } from '@ampli/ui';
import {
  ThumbsUpBalloon as ThumbsUpBalloonIcon,
  LaptopSmartphone as LaptopSmartphoneIcon,
  PigBank as PigBankIcon,
  LaptopChat as LaptopChatIcon,
  Attendant as AttendantIcon,
  PhoneStar as PhoneStarIcon,
} from '@ampli/icon';
import { string } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import CardInfo from './card-info';
import StudentIllustration from './student-illustration';

export const WHY_AMPLI_VALUES = [
  {
    description: (
      <Text as="h3">
        {'Cursos de '}
        <strong>{'rápida duração'}</strong>
        {' para você entrar logo no mercado de trabalho'}
      </Text>
    ),
    icon: ThumbsUpBalloonIcon,
  },
  {
    description: (
      <Text as="h3">
        {'Assista às aulas pelo seu '}
        <strong>{'celular'}</strong>
        {' ou computador'}
      </Text>
    ),
    icon: LaptopSmartphoneIcon,
  },
  {
    description: (
      <Text as="h3">
        {'Sem valores abusivos. Preços reajustados '}
        <strong>{'apenas'}</strong>
        {' pela inflação'}
      </Text>
    ),
    icon: PigBankIcon,
  },
  {
    description: (
      <Text as="h3">
        {'Baixe as aulas e '}
        <strong>{'assista sem'}</strong>
        {' internet'}
      </Text>
    ),
    icon: LaptopChatIcon,
  },
  {
    description: (
      <Text as="h3">
        {'Suporte aos alunos em '}
        <strong>{'até 3 dias'}</strong>
        {' úteis'}
      </Text>
    ),
    icon: AttendantIcon,
  },
  {
    description: (
      <Text as="h3">
        {'Experiência voltada para o '}
        <strong>{'ambiente'}</strong>
        {' digital'}
      </Text>
    ),
    icon: PhoneStarIcon,
  },
];

const WhyAmpliText = () => (
  <Text
    as="h2"
    className={cx(
      'why-ampli-title-wrap',
      css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `
    )}
  >
    <Text size="huge" color="brand-light">
      <strong>{'Por que a '}</strong>
    </Text>
    <Text
      size={px2grid(72)}
      color="semantic-alert"
      className={css`
        ${mediaQuery('mobile')} {
          font-weight: 100;
        }
      `}
    >
      {'Ampli?'}
    </Text>
  </Text>
);

export const MIN_WIDTH_DESKTOP = 1100;

const WhyAmpli = ({ className, ...props }) => {
  const breakpoint = useBreakpoint(true);

  const isDesktop = React.useMemo(
    () =>
      breakpoint?.isDesktop &&
      breakpoint?.currentSize?.width >= MIN_WIDTH_DESKTOP,
    [breakpoint]
  );
  return (
    <div
      {...props}
      className={cx(
        'why-ampli-wrap',
        css`
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
        `,
        className
      )}
    >
      <div
        className={cx(
          'why-ampli-content',
          css`
            display: flex;
            flex-direction: column;
            overflow: auto;

            ${mediaQuery('desktop')} {
              flex-direction: row;
              justify-content: space-between;
              max-width: ${px2grid(1200)};
            }
          `
        )}
      >
        <div
          className={cx(
            'why-ampli-title-content',
            css`
              display: flex;
              justify-content: center;
              align-items: flex-end;
              margin-bottom: ${px2grid(40)};

              ${mediaQuery('desktop')} {
                justify-content: flex-start;
                flex-direction: column;
                margin-bottom: 0;
              }

              ${mediaQuery('mobile')} {
                width: 90%;
                margin: 0 auto;
              }
            `
          )}
        >
          {isDesktop && <WhyAmpliText />}
          <StudentIllustration
            className={cx(
              'student-illustration',
              css`
                width: ${px2grid(150)};
                height: ${px2grid(150)};

                ${mediaQuery('desktop')} {
                  width: ${px2grid(380)};
                  height: ${px2grid(390)};
                }
              `
            )}
          />
          {!isDesktop && <WhyAmpliText />}
        </div>
        <div
          className={cx(
            'why-amply-cards-wrap',
            css`
              display: flex;
              flex-wrap: wrap;
              margin-left: ${px2grid(30)};
              justify-content: space-between;
              width: ${px2grid(700)};
              overflow: auto;

              ${mediaQuery('tablet')} {
                margin-left: 0;
              }

              ${mediaQuery('desktop')} {
                justify-content: space-between;
                max-width: ${px2grid(650)};
              }
            `
          )}
        >
          {WHY_AMPLI_VALUES.map((item, index) => (
            <CardInfo key={`why_ampli_card_${index}`} icon={item.icon}>
              {item.description}
            </CardInfo>
          ))}
        </div>
      </div>
    </div>
  );
};

WhyAmpli.propTypes = {
  className: string,
};

export default WhyAmpli;
