import { DateTime, Interval } from 'luxon';
export var legalAgeComparator = function legalAgeComparator(birthDate) {
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _settings$dateFormat = settings.dateFormat,
      dateFormat = _settings$dateFormat === void 0 ? 'dd/MM/yyyy' : _settings$dateFormat,
      _settings$legalAge = settings.legalAge,
      legalAge = _settings$legalAge === void 0 ? 18 : _settings$legalAge;
  return !!birthDate && Interval.fromDateTimes(DateTime.fromFormat(birthDate, dateFormat), DateTime.local()).length('years') >= legalAge;
};
export var includesValues = function includesValues(origin, contain) {
  return origin.some(function (r) {
    return contain.indexOf(r) >= 0;
  });
};