import React from 'react';
import { Link } from 'react-router-dom';
import { cx, css, px2grid, Text, Breadcrumb, mediaQuery } from '@ampli/ui';
import { string, arrayOf, shape, bool } from '@ampli/utils';

const Breadcrumbs = ({
  items,
  color = 'white',
  className,
  shadow = true,
  ...props
}) => {
  const links = items.slice(0, items.length - 1);
  const currentPage = items[items.length - 1];

  return (
    <Breadcrumb
      color={color}
      className={cx(
        'breadcrumbs',
        css`
          margin-bottom: ${px2grid(28)} !important;

          & > li {
            ${mediaQuery('desktop')} {
              padding-right: ${px2grid(36)} !important;
            }

            & > a {
              text-decoration: none;
            }
          }
        `,
        className
      )}
      shadow={shadow}
      showOnlyLatestOnMobile={true}
      {...props}
    >
      {links.map((item) =>
        item.href ? (
          <a href={item.href}>{`${item.label}`}</a>
        ) : (
          <Link to={item.url} key={item.url}>
            {`${item.label}`}
          </Link>
        )
      )}
      <Text>{currentPage.label}</Text>
    </Breadcrumb>
  );
};
Breadcrumbs.propTypes = {
  items: arrayOf(shape({ label: string, url: string })),
  color: string,
  className: string,
  shadow: bool,
};

export default Breadcrumbs;
