import React from 'react';

var useHideMundialeChatbot = function useHideMundialeChatbot() {
  React.useEffect(function () {
    var chatbot = document.getElementById('blip-chat-container');

    if (chatbot) {
      chatbot.setAttribute('style', 'display: none !important');
    }

    return function () {
      if (chatbot) {
        chatbot.setAttribute('style', 'display: block !important');
      }
    };
  });
};

export default useHideMundialeChatbot;