import React from 'react';
import { Button, cx } from '@ampli/ui';
import {
  SvgAnhangueraFullLogo,
  SvgAnhangueraLogo,
} from '../../assets/anhanguera';
import { useBreakpoint } from '@ampli/hooks';
import styles from './styles';

interface AnhangueraFloatButtonProps {
  className?: string;
}

const AnhangueraFloatButton: React.FC<AnhangueraFloatButtonProps> = ({
  className,
}: AnhangueraFloatButtonProps) => {
  const breakpoint = useBreakpoint();
  const isMobile = React.useMemo(() => breakpoint !== 'desktop', [breakpoint]);

  return (
    <Button
      onClick={() => window.open('https://www.anhanguera.com/', '_blank')}
      className={cx('floating-whatsapp-button', styles.button, className)}
    >
      {isMobile ? (
        <SvgAnhangueraLogo />
      ) : (
        <>
          By <SvgAnhangueraFullLogo />
        </>
      )}
    </Button>
  );
};

export default AnhangueraFloatButton;
