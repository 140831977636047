import React, { FunctionComponent } from 'react';
import {
  DialogBackdrop,
  DialogAlert,
  DialogCloseButton,
  DialogSection,
  DialogFooter,
  RegressiveCounter,
  Text,
} from '@ampli/ui';
import {
  BlackWeekPopupLogoIllustration,
  SpiderIllustration,
  DottedLineIllustration,
} from '../../assets/illustration';
import styles from './styles';
import { AMPLI_BLACK_WEEK_END_DATE } from '../../constants';

interface BlackWeekPopoverProps {
  dialog: {
    hide: () => void;
    show: () => void;
  };
}
const BlackWeekPopover: FunctionComponent<BlackWeekPopoverProps> = ({
  dialog,
}: BlackWeekPopoverProps) => {
  const goToCourses = () => {
    location.href = `/graduacao?utm_source=blackfriday&utm_medium=todos&utm_campaign=blackfriday2022&utm_content=1bbc47b0-7eac-43f7-b34d-b7e3c5cfafbd&sellerId=1bbc47b0-7eac-43f7-b34d-b7e3c5cfafbd`;
  };

  return (
    <>
      <DialogBackdrop {...dialog} />
      <DialogAlert
        className={styles.container}
        {...dialog}
        aria-labelledby="modalTitle"
      >
        <DialogCloseButton onClick={dialog.hide} />
        <DialogSection className={styles.section}>
          <BlackWeekPopupLogoIllustration className={styles.logo} />
          <Text className={styles.description} color="semantic-alert-lighter">
            Antecipamos a <strong>Black Friday</strong> para assustar o medo de
            estudar neste Halloween.
          </Text>
        </DialogSection>
        <DialogFooter className={styles.footer}>
          <RegressiveCounter
            showCobweb
            className={styles.regressiveCounter}
            endDate={AMPLI_BLACK_WEEK_END_DATE}
            timeFontSize="32px"
            timeDescriptionFontSize="16px"
          />
          <DottedLineIllustration className={styles.dottedIllustration} />
          <button onClick={goToCourses} className={styles.button}>
            Ver os cursos <SpiderIllustration className={styles.spiderIcon} />
          </button>
        </DialogFooter>
      </DialogAlert>
    </>
  );
};

export default BlackWeekPopover;
