import React from 'react';
import { cx, css, Text } from '@ampli/ui';
import { qrCodeContainer, qrCodeImageContainer } from './styles';
import qrCodeImage from '../../assets/qrcode-mec.png';

const QRCodeMec = () => (
  <div className={cx('qrcode-mec', qrCodeContainer)}>
    <Text color="white" fontWeight="700" size="large">
      Consulte aqui o cadastro da Instituição no Sistema e-MEC
    </Text>
    <div className={qrCodeImageContainer}>
      <a
        href="https://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MjQy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={qrCodeImage}
          alt="QR Code e-MEC"
          className={css`
            width: 100%;
          `}
        />
      </a>
    </div>
  </div>
);

export default QRCodeMec;
