import { css, getColor, px2grid, mediaQuery } from '@ampli/ui';

export default {
  container: css`
    height: 100%;
    margin-top: ${px2grid(30)};

    ${mediaQuery('desktop')} {
      padding-inline: 20%;
      margin-top: ${px2grid(4)};
    }
    background: ${getColor('neutral-background')};
  `,
  content: (isBlackWeek: boolean, isInscriptionThirdPage: boolean) => css`
    height: 100%;
    background: white;
    padding: 5% ${px2grid(32)};
    ${mediaQuery('desktop')} {
      padding: 15% ${px2grid(48)} 5% ${px2grid(48)};
      ${isBlackWeek && `margin-top: ${px2grid(65)}`};
    }
    form {
      section {
        div {
          padding: 0px;
          div {
            div {
              button {
                padding: ${px2grid(6)};
              }
            }
          }
        }
        .radio-label {
          ${isInscriptionThirdPage && `padding: ${px2grid(20)}`}
        }
      }
    }
  `,
};
