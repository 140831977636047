const setPopoverOnceOpened = () =>
  sessionStorage.setItem('isPopoverClosed', true);

export const useOpenBlackWeekPopupOnUserIdleTime = (
  dialogBlackWeekState,
  isEJAPage
) => {
  if (typeof window === 'undefined') return;
  setInterval(timerIncrement, 15000);

  const userMaxInactiveMinutes = 2;
  let userIdleTime = 0;

  const resetUserIdleTime = () => (userIdleTime = 0);

  document.addEventListener('mousemove', resetUserIdleTime);
  document.addEventListener('keydown', resetUserIdleTime);
  document.addEventListener('keydown', resetUserIdleTime);

  function timerIncrement() {
    const isSubscriptionModalOpen = JSON.parse(
      sessionStorage.getItem('isSubscriptionModalOpen')
    );
    const isPopoverClosed = JSON.parse(
      sessionStorage.getItem('isPopoverClosed')
    );
    userIdleTime += 1;
    if (
      userIdleTime > userMaxInactiveMinutes &&
      !isEJAPage &&
      !isSubscriptionModalOpen &&
      !isPopoverClosed
    ) {
      dialogBlackWeekState.show();
      setPopoverOnceOpened();
    }
  }

  return () => {
    document.removeEventListener('mouseout', resetUserIdleTime);
    document.removeEventListener('keydown', resetUserIdleTime);
    document.removeEventListener('keydown', resetUserIdleTime);
  };
};

export const useOpenBlackWeekPopupOnUserMouseOut = (
  dialogBlackWeekState,
  isEJAPage
) => {
  const showModal = (event) => {
    const isPopoverClosed = JSON.parse(
      sessionStorage.getItem('isPopoverClosed')
    );
    if (isEJAPage || isPopoverClosed) return;
    if (!event.toElement && !event.relatedTarget) {
      setTimeout(() => {
        dialogBlackWeekState.show();
        setPopoverOnceOpened();
      }, 500);
    }
  };
  typeof window !== 'undefined' &&
    document.addEventListener('DOMContentLoaded', () => {
      document.addEventListener('mouseout', showModal);
    });
  return () => document.removeEventListener('mouseout', showModal);
};
