import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { cx, css, getSpaceSize, Text, PrimaryButton, Button } from '@ampli/ui';
import { DoubleChevron } from '@ampli/icon';
import { useBreakpoint } from '@ampli/hooks';
import { func, string, bool } from '@ampli/utils';
import { useGetBannerByLocation } from '@ampli/services';
import {
  bannerHomeImage,
  bannerContainer,
  bannerHomeContent,
  bannerTitle,
  bannerDescriptionWrap,
  bannerBlackWeekSubtitle,
  bannerHomeText,
  bannerCenterTextWrap,
  bannerCenterText,
  bannerHomeFooter,
  bannerButton,
  doubleChevronIcon,
  mecBadgeWrap,
  mecBadgeMobile,
  mecBadgeDesktop,
} from './styles';

import { MecBadgeLarge, MecBadgeMobile } from '../mec-badge';
interface BannerHomeCourseProps {
  CTARedirectionURL: string;
  onClickScroll: () => void;
  isBlackWeek?: boolean;
}

const BannerHomeCourse: FunctionComponent<BannerHomeCourseProps> = ({
  CTARedirectionURL,
  onClickScroll,
  isBlackWeek,
}: BannerHomeCourseProps) => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint !== 'desktop' && breakpoint !== 'tablet';
  const { data: bannerData } = useGetBannerByLocation('HOME');
  const bannerFromAdmin = isMobile
    ? {
        image: bannerData?.mobileImage.baseUrl + '/mobile',
        description: bannerData?.mobileImage.description,
      }
    : {
        image: bannerData?.desktopImage.baseUrl + '/original',
        description: bannerData?.desktopImage.description,
      };

  return (
    <div className={bannerContainer}>
      {bannerData && (
        <img
          className={cx('banner-home-container', bannerHomeImage)}
          src={
            bannerFromAdmin.image ||
            require('../../assets/banner-pagina-inicial-min.jpg')
          }
          alt={bannerFromAdmin.description}
        />
      )}
      <div className={cx('banner-home-content', bannerHomeContent)}>
        <div className={cx('banner-home-text', bannerHomeText)}>
          {isBlackWeek ? (
            <Text
              as="h1"
              className={cx(
                'banner-description-wrap',
                bannerDescriptionWrap(isBlackWeek)
              )}
            >
              <Text
                fontWeight={100}
                color="white"
                className={bannerTitle(isBlackWeek)}
              >
                {'Estudar te assusta?'}
              </Text>
              <Text
                fontWeight={600}
                color="white"
                className={bannerBlackWeekSubtitle}
              >
                {'A gente segura a sua mão'}
              </Text>
            </Text>
          ) : (
            <Text
              as="h1"
              className={cx(
                'banner-description-wrap',
                bannerDescriptionWrap(isBlackWeek)
              )}
            >
              <Text
                fontWeight={100}
                color="white"
                className={bannerTitle(isBlackWeek)}
              >
                {'Educação '}
              </Text>
              <Text className={bannerCenterTextWrap}>
                <Text
                  fontWeight={'normal'}
                  className={cx('banner-center-text', bannerCenterText)}
                  size={isMobile ? 'xx-large' : 'huge'}
                  color="white"
                >
                  {'de qualidade '}
                </Text>
                <Text
                  fontWeight={600}
                  size={isMobile ? 'xx-large' : 'huge'}
                  color="white"
                >
                  {'ao alcance de todos'}
                </Text>
              </Text>
            </Text>
          )}

          <div className={cx('banner-home-footer', bannerHomeFooter)}>
            <PrimaryButton
              as={Link}
              to={CTARedirectionURL}
              className={cx('banner-button', bannerButton)}
            >
              <div
                className={css`
                  padding: 0 ${getSpaceSize('large')};
                `}
              >
                <Text color="white">
                  <strong>Conheça nossos cursos</strong>
                </Text>
              </div>
            </PrimaryButton>

            {isMobile && (
              <Button onClick={onClickScroll}>
                <DoubleChevron
                  className={cx('double-chevron-icon', doubleChevronIcon)}
                />
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={cx('mec-badge-wrap', mecBadgeWrap(isBlackWeek))}>
        {isMobile ? (
          <MecBadgeMobile className={cx('banner-mec-badge', mecBadgeMobile)} />
        ) : (
          <MecBadgeLarge className={cx('banner-mec-badge', mecBadgeDesktop)} />
        )}
      </div>
    </div>
  );
};

BannerHomeCourse.propTypes = {
  CTARedirectionURL: string,
  onClickScroll: func,
  isBlackWeek: bool,
};

export default BannerHomeCourse;
