import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["data"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useQuery } from '../hooks';
import { GET_BANNER_BY_LOCATION } from './queries';
export var useGetBannerByLocation = function useGetBannerByLocation(location) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _useQuery = useQuery(GET_BANNER_BY_LOCATION, _objectSpread(_objectSpread({
    skip: !location
  }, options), {}, {
    variables: {
      location: location
    },
    fetchPolicy: 'no-cache'
  })),
      data = _useQuery.data,
      rest = _objectWithoutProperties(_useQuery, _excluded);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};