import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DropdownMenu from './dropdown-menu';
import { cx } from '@ampli/ui';

import { ArrowDown, ArrowUp } from '@ampli/icon';

import { string, arrayOf, shape, func, bool, number } from '@ampli/utils';

import styles, { contactMenuLink } from './styles';

const MenuLink = ({ active, index, onClick, ...props }) => {
  return React.createElement(
    (onClick && 'button') || (props.target === '_blank' && 'a') || Link,
    {
      ...props,
      ...(onClick && {
        onClick: (e) => {
          e.preventDefault();
          onClick(e);
        },
      }),
      className: cx(
        'menu-link',
        active && 'active',
        styles.menuLink,
        index === 0 && 'isFirst',
        props.className
      ),
    }
  );
};
MenuLink.propTypes = {
  className: string,
  active: bool,
  onClick: func,
  index: number,
  target: string,
};

const MenuDesktop = ({
  items,
  visibleSection,
  isInscriptionPage,
  ...props
}) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  return (
    <nav {...props} className={cx('nav', styles.nav, props.className)}>
      <ul className={cx('menu', styles.menu)}>
        {items.map((item, index) => (
          <li
            key={`menu_item_${index}`}
            className={cx(
              'menu-item',
              styles.menuItem(item, isInscriptionPage)
            )}
          >
            {item.label === 'cursos' ? (
              <MenuLink
                active={visibleSection === item?.label}
                index={index}
                onClick={() => {
                  item.label === 'cursos' && setIsSubmenuOpen(!isSubmenuOpen);
                }}
                {...item}
              >
                {item.label}
                {!isSubmenuOpen ? (
                  <ArrowDown
                    className={cx('dropdown-icon', styles.dropdownIcon)}
                  />
                ) : (
                  <>
                    <DropdownMenu />
                    <ArrowUp
                      className={cx('dropdown-icon', styles.dropdownIcon)}
                    />
                  </>
                )}
              </MenuLink>
            ) : (
              <MenuLink
                active={visibleSection === item?.label}
                index={index}
                className={contactMenuLink(item, isInscriptionPage)}
                {...item}
              >
                {item.label}
              </MenuLink>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

MenuDesktop.propTypes = {
  className: string,
  items: arrayOf(
    shape({
      label: string,
      onClick: func,
    })
  ),
  visibleSection: string,
  isInscriptionPage: bool,
};

export default MenuDesktop;
