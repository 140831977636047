import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _typeof from "@babel/runtime/helpers/typeof";
var _excluded = ["language"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { trim, isString, size, isNumber, deburr, toLower } from 'lodash';
import { DateTime, Duration } from 'luxon';
var possibleDurationTypes = ['years', 'quarters', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
export var phoneFormatter = function phoneFormatter(value) {
  if (!isString(value)) {
    throw new Error('Phone should be string, but get: ' + _typeof(value));
  }

  var phone = value.replace(/[^\d]+/g, '').split('');

  if (size(phone) < 11) {
    return phone.join('');
  }

  phone.splice(0, 0, '(');
  phone.splice(3, 0, ')');
  phone.splice(4, 0, ' ');
  phone.splice(-4, 0, '-');
  return phone.join('');
};
export var boletoCodeFormatter = function boletoCodeFormatter(value) {
  if (!isString(value)) {
    throw new Error('Boleto code should be string, but get: ' + _typeof(value));
  }

  var isFormatted = value.includes('.');
  if (isFormatted) return value;
  var boleto = trim(value).replace(/[^\d]+/g, '').split('');

  if (size(boleto) < 47) {
    throw new Error('Invalid boleto code size: ' + size(boleto));
  }

  for (var i = 0; i < 30; i += 10) {
    boleto[i + 4] += '.';
    boleto[i + 9] += ' ';
  }

  boleto.splice(-16, 0, ' ');
  return boleto.join('');
};
export var numberFormatter = function numberFormatter(value) {
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _settings$language = settings.language,
      language = _settings$language === void 0 ? 'pt-BR' : _settings$language,
      options = _objectWithoutProperties(settings, _excluded);

  var formatter = new Intl.NumberFormat(language, options);
  return formatter.format(value || 0);
};
export var moneyFormatter = function moneyFormatter(value) {
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _settings$currency = settings.currency,
      currency = _settings$currency === void 0 ? 'BRL' : _settings$currency;
  return numberFormatter(value, _objectSpread({
    style: 'currency',
    currency: currency
  }, settings));
};
export var addressFormatter = function addressFormatter(address) {
  var _address$city, _address$state;

  return [address.street, address.number, address.complement, address.neighbourhood, (_address$city = address.city) === null || _address$city === void 0 ? void 0 : _address$city.name, (_address$state = address.state) === null || _address$state === void 0 ? void 0 : _address$state.acronym].filter(function (a) {
    return a;
  }).join(', ');
};
export var formatIsoDate = function formatIsoDate(isoDate) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'dd/MM/yyyy';
  return DateTime.fromISO(isoDate).toFormat(format);
};
export var formatIsoToLocaleDate = function formatIsoToLocaleDate(isoDate) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DATE_SHORT';
  return DateTime.fromISO(isoDate).toLocaleString(DateTime[format] || format);
};
export var formatDuration = function formatDuration(startDate, endDate) {
  var format = 'dd/MM';
  return "".concat(formatIsoDate(startDate, format), " - ").concat(formatIsoDate(endDate, format));
};
export var formatInterval = function formatInterval(interval) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DATE_SHORT';
  var separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ' - ';
  if (!interval) return '';
  return "".concat(interval.start.toLocaleString(DateTime[format] || format)).concat(separator).concat(interval.end.toLocaleString(DateTime[format] || format));
};
export var fullDateLongString = function fullDateLongString(date) {
  if (!date) {
    return '';
  }

  return "".concat(date.day, " de ").concat(date.monthLong, " de ").concat(date.year);
};
export var fullDateLongStringInterval = function fullDateLongStringInterval(startDate, endDate) {
  if (!startDate || !endDate) {
    return '';
  }

  var longStartDate = fullDateLongString(startDate);
  var longEndtDate = fullDateLongString(endDate);

  if (startDate.year === endDate.year) {
    return "\n    ".concat(longStartDate.split(" de ".concat(startDate.year))[0], " at\xE9 ").concat(longEndtDate);
  }

  return "".concat(longStartDate, " at\xE9 ").concat(longEndtDate);
};
export var intervalInDays = function intervalInDays(startDate, endDate) {
  var diffInMs = new Date(endDate) - new Date(startDate);
  return "".concat(Math.floor(diffInMs / (1000 * 60 * 60 * 24)));
};
export var formatDate = function formatDate(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DATE_SHORT';
  return date.toLocaleString(DateTime[format] || format);
};
export var formatTimeSeconds = function formatTimeSeconds(seconds) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'mm:ss';
  return seconds ? Duration.fromObject({
    seconds: seconds
  }).toFormat(format) : '00:00';
};
export var cpfFormatter = function cpfFormatter(value) {
  if (!isString(value)) {
    throw new Error('CPF should be string, but get: ' + _typeof(value));
  }

  var cpf = value.replace(/[^\d]+/g, '').split('');

  if (size(cpf) < 11) {
    throw new Error('Invalid CPF size: ' + size(cpf));
  }

  cpf.splice(3, 0, '.');
  cpf.splice(7, 0, '.');
  cpf.splice(11, 0, '-');
  return cpf.join('');
};
export var cnpjFormatter = function cnpjFormatter(value) {
  if (!isString(value)) {
    throw new Error('CNPJ should be string, but get: ' + _typeof(value));
  }

  var cnpj = value.replace(/[^\d]+/g, '').split('');

  if (size(cnpj) < 14) {
    throw new Error('Invalid CNPJ size: ' + size(cnpj));
  }

  cnpj.splice(2, 0, '.');
  cnpj.splice(6, 0, '.');
  cnpj.splice(10, 0, '/');
  cnpj.splice(15, 0, '-');
  return cnpj.join('');
};
export var onlyNumbersFormatter = function onlyNumbersFormatter(value) {
  var ONLY_NUMBERS_REGEX = /[^\d]/g;
  return value.replace(ONLY_NUMBERS_REGEX, '');
};
/**
 * Converts month number in a yearly string
 * @param {number} months - Number of months (must be an integer)
 * @returns {string} Formatted String
 *
 * @example
 *
 * const notEvenAYear = formatMonthsInYearlyString(5);
 * // expected output: "5 meses"
 * const moreThanAYear = formatMonthsInYearlyString(18);
 * // expected output: "1 ano e 6 meses"
 * const exactlyTwoYears = formatMonthsInYearlyString(24);
 * // expected output: "2 anos"
 * const moreThanThreeYears = formatMonthsInYearlyString(43);
 * // expected output: "3 anos e 7 meses"
 */

export var formatMonthsInYearlyString = function formatMonthsInYearlyString(months) {
  function yearTimeString(months) {
    var timeInYears = Math.floor(months / 12);
    return timeInYears < 1 ? '' : "".concat(timeInYears, " ano").concat(timeInYears > 1 ? 's' : '');
  }

  function monthTimeString(months) {
    var monthWord = months % 12 === 1 ? 'mês' : 'meses';
    if (months % 12 === 0) return '';else return months < 12 ? "".concat(months, " ").concat(monthWord) : " e ".concat(months % 12, " ").concat(monthWord);
  }

  return months && typeof months === 'number' ? "".concat(yearTimeString(months)).concat(monthTimeString(months)) : null;
};
/**
 * Converts a number duration into ISO8601 Duration format
 * @param {number} duration - Duration value
 * @param {string} durationType - Duration type from possible values [
    'years',
    'quarters',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds',
  ]
 * @returns {string} Duration in ISO8601 format
 * @example parseDurationToISO8601Format(6, 'months') => 'P6M';
 */

export var parseDurationToISO8601Format = function parseDurationToISO8601Format(duration, durationType) {
  return duration && durationType && possibleDurationTypes.includes(durationType) && Duration.fromObject(_defineProperty({}, durationType, duration)).toISO();
};
/**
  * Extracts the duration value from a duration string in ISO8601 Duration format
  * @param {string} duration - ISO8601 Duration string
  * @param {string} durationType - What duration type you want to extract from possible values [
     'years',
     'quarters',
     'months',
     'weeks',
     'days',
     'hours',
     'minutes',
     'seconds',
     'milliseconds',
   ]
  * @returns {number} Parsed object
  * @example parseDurationFromISO8601Format('P6M', 'months') => 6;
  */

export var parseDurationFromISO8601Format = function parseDurationFromISO8601Format(duration, durationType) {
  if (typeof duration === 'number') return duration;else return duration && durationType && possibleDurationTypes.includes(durationType) && Duration.fromISO(duration)[durationType];
};
/**
 * Gets a price value and return it split into integer and decimal parts
 * @param {(number|string)} price in number or parsedString format
 * @returns {{integer: string, decimal: string}} split object
 * @example
 * splitPrice(149.99) => { integer: '149', decimal: '99' };
 * splitPrice(99) => { integer: '99', decimal: '00' };
 * splitPrice('54.99') => { integer: '54', decimal: '99' };
 */

export var splitPrice = function splitPrice(price) {
  if (!isNumber(price) && !isString(price)) throw new Error('Price should be a number or a parsed string, but got: ' + _typeof(price));
  var priceToFloat = parseFloat(price).toFixed(2);
  if (price === 'NaN') throw new Error('Recieved price string is not a number');
  var splitPrice = priceToFloat.toString().split('.');
  return {
    integer: splitPrice[0],
    decimal: splitPrice[1]
  };
};
/**
 * Returns built image url from S3
 * @param {{baseUrl: string, formats: [string]}} imageObject
 * @param {string} desiredFormat
 * @returns {string}
 */

export var imageURLFormatter = function imageURLFormatter(imageObject) {
  var _imageObject$formats;

  var desiredFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'original';
  if (!imageObject || !imageObject.baseUrl || !((_imageObject$formats = imageObject.formats) !== null && _imageObject$formats !== void 0 && _imageObject$formats.length) || !imageObject.formats.includes(desiredFormat)) return null;
  return "".concat(imageObject.baseUrl, "/").concat(desiredFormat);
};
export var localDateFormatToIsoDate = function localDateFormatToIsoDate(value) {
  return value.split('/').reverse().join('-');
};
/**
 * Convert the text to lowercase and into a simple ASCII format
 * @param {string} text
 * @returns Text in lowercase and without special characters
 * @example "Administração" -> "administracao"
 */

export var normalizeText = function normalizeText(text) {
  return deburr(toLower(trim(text)));
};
export function pluralizeString(_ref) {
  var value = _ref.value,
      word = _ref.word,
      _ref$suffix = _ref.suffix,
      suffix = _ref$suffix === void 0 ? 's' : _ref$suffix;
  return value == 1 ? word : word + suffix;
}
export var formatSupportedDocumentExtension = function formatSupportedDocumentExtension(extensions) {
  if (!extensions) return;
  return extensions.map(function (value) {
    return value.split('/').pop().toUpperCase();
  }).join(', ').replace(/, ([^,]*)$/, ' e $1');
};