import React from 'react';

interface SvgAnhangueraFullLogoProps {
  width?: string;
  height?: string;
  color?: string;
}

const SvgAnhangueraFullLogo: React.FC<SvgAnhangueraFullLogoProps> = ({
  width = '139',
  height = '31',
  color = '#1D1D1D',
  ...props
}: SvgAnhangueraFullLogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 139 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.94389 18.7551C7.54487 19.261 6.1837 19.8661 4.87075 20.5657C4.51146 20.772 4.2101 21.0656 3.99464 21.4195L1.86431 25.2859C1.69523 25.5933 1.83203 25.7563 2.17171 25.6494C2.17171 25.6494 3.02631 25.3751 3.85631 25.133C5.87136 24.5412 9.59637 23.6644 9.59637 23.6644C9.98309 23.5514 10.3148 23.3004 10.5286 22.9589L13.462 17.6338C13.6311 17.3264 13.4951 17.165 13.1592 17.2741C13.1531 17.2741 11.065 17.945 8.94389 18.7551Z"
        fill="#CD4C0A"
      />
      <path
        d="M27.8594 21.0476C27.9322 21.0518 28.0047 21.0352 28.0684 20.9997C28.1322 20.9643 28.1845 20.9115 28.2193 20.8474C28.2541 20.7833 28.27 20.7107 28.265 20.6379C28.2601 20.5652 28.2346 20.4954 28.1914 20.4366L25.3833 15.3375C25.2851 15.1799 25.1447 15.0532 24.9779 14.9716C24.8112 14.8901 24.6249 14.8571 24.4403 14.8763L18.8132 15.8278C18.7378 15.8356 18.6656 15.8628 18.6037 15.9067C18.5419 15.9506 18.4923 16.0097 18.46 16.0783C18.4276 16.1469 18.4135 16.2227 18.4189 16.2983C18.4244 16.374 18.4493 16.447 18.4912 16.5102L21.0273 21.1106C21.127 21.2702 21.2672 21.4006 21.4336 21.4883C21.6001 21.5761 21.7869 21.6181 21.9749 21.6101L27.8594 21.0476Z"
        fill="#CD4C0A"
      />
      <path
        d="M24.0438 12.8974L23.5311 11.9667L22.5451 10.1761L21.2241 7.77831C21.1338 7.62603 20.9942 7.50917 20.8284 7.4471C20.6627 7.38504 20.4807 7.38151 20.3126 7.43709C16.9986 8.3649 13.8188 9.71853 10.8529 11.4641C9.85383 12.1266 8.98156 12.8475 8.98156 12.8475C8.66543 13.1253 8.39516 13.4513 8.18077 13.8135L5.81219 18.1133C5.64389 18.4208 5.76685 18.5506 6.08732 18.4023C6.08732 18.4023 6.70213 18.1187 7.3938 17.8298C9.8662 16.8023 12.4069 15.9474 14.9975 15.2714L15.1512 15.2314C18.1484 14.4629 23.7187 13.5591 23.7187 13.5591C23.7936 13.555 23.8661 13.531 23.9288 13.4896C23.9914 13.4482 24.0418 13.3908 24.0749 13.3234C24.108 13.2561 24.1226 13.1811 24.1171 13.1062C24.1116 13.0313 24.0863 12.9593 24.0438 12.8974Z"
        fill="#E85A13"
      />
      <path
        d="M10.6954 9.92709C12.1461 9.07301 13.6417 8.29773 15.1758 7.60463C16.7682 6.9437 19.5987 6.12523 19.5987 6.12523C19.6747 6.10771 19.7456 6.07231 19.8052 6.02196C19.8649 5.97161 19.9117 5.90776 19.9417 5.83571C19.9718 5.76365 19.9842 5.68547 19.978 5.60765C19.9718 5.52982 19.9472 5.4546 19.9061 5.38822L17.2854 0.628016C16.7475 -0.242716 15.9075 -0.208131 15.3895 0.732536L15.3833 0.737144L10.4502 9.69346C10.2812 9.99933 10.3918 10.1046 10.6954 9.92709Z"
        fill="#F38517"
      />
      <path
        d="M32.5843 28.4053L29.5586 22.915C29.4874 22.7716 29.3819 22.648 29.2515 22.5551C29.121 22.4622 28.9697 22.4029 28.8109 22.3825L28.5872 22.3986C20.3726 22.8831 12.2335 24.2457 4.30897 26.4633C2.85878 26.8875 1.15805 27.4685 1.15805 27.4685C0.956593 27.5177 0.769171 27.6126 0.610306 27.7458C0.451442 27.8791 0.325404 28.0472 0.241972 28.237L0.152826 28.403C-0.271396 29.3191 0.213538 30.0454 1.28562 30.0454L1.33942 30.0515H31.453C32.5228 30.0492 33.0085 29.3206 32.5843 28.4053Z"
        fill="#CD4C0A"
      />
      <path
        d="M42.9545 14.8744H46.8278L45.8749 11.4752C45.4436 9.96685 45.1154 8.43088 44.8927 6.87794C44.7244 8.03072 44.3317 9.87132 43.9105 11.4752L42.9545 14.8744ZM47.3043 16.6466H42.4773L41.3829 20.5745C41.2991 20.8381 41.2153 20.9587 40.3162 20.9587C40.0088 20.9587 39.4185 20.9341 38.8852 20.888L43.8767 4.41177C43.9328 4.17276 44.2418 4.07669 44.7459 4.07669C45.1028 4.0732 45.4595 4.09735 45.8126 4.14893L50.8395 20.5507C50.8956 20.8158 50.755 20.9587 49.8581 20.9587C49.5207 20.9587 49.0719 20.9587 48.5109 20.9111L47.3043 16.6466Z"
        fill={color}
      />
      <path
        d="M51.99 9.51241C53.4159 9.01002 54.9121 8.73512 56.4235 8.69778C59.37 8.69778 60.3799 9.58389 60.3799 11.9056V20.526C60.3799 20.9103 60.3245 20.9103 58.0236 20.9103V12.4812C58.0236 10.9734 57.7423 10.3993 56.3398 10.3993C55.6266 10.4065 54.9218 10.553 54.2648 10.8304V20.5268C54.2648 20.9111 54.2087 20.9111 51.9923 20.9111L51.99 9.51241Z"
        fill={color}
      />
      <path
        d="M62.4287 4.19657C62.4287 3.3097 62.8214 3.3097 64.702 3.3097V9.63C65.6288 9.00312 66.7265 8.67756 67.8453 8.69779C70.2584 8.69779 70.7656 9.84672 70.7656 11.8572V20.5245C70.7656 20.9088 70.6542 20.9088 68.4086 20.9088V12.409C68.4086 11.3553 68.1834 10.4938 66.8093 10.4938C66.0545 10.4905 65.318 10.7253 64.7043 11.1647V20.5253C64.7043 20.9095 64.6759 20.9095 62.431 20.9095L62.4287 4.19657Z"
        fill={color}
      />
      <path
        d="M76.8238 15.0427C75.7026 15.0427 74.7488 15.3777 74.7488 17.4612C74.7488 19.2088 75.4782 19.5201 76.5733 19.5201C77.0983 19.5366 77.6161 19.395 78.0596 19.1135C78.1442 18.3235 78.1442 16.5513 78.1442 15.2586C77.7189 15.1145 77.2728 15.0415 76.8238 15.0427ZM72.6961 9.34411C74.0642 8.85847 75.5125 8.63908 76.9629 8.69779C79.3753 8.69779 80.4413 9.46631 80.4413 11.6182V12.887C80.4413 15.6406 80.4136 18.5133 80.2737 20.3339C79.0534 20.901 77.7188 21.1794 76.3735 21.1478C74.3246 21.1708 72.3603 20.7889 72.3603 17.5073C72.3603 14.1804 74.2117 13.581 76.093 13.581C76.7887 13.5628 77.4822 13.6682 78.1411 13.8922V12.0016C78.1411 10.8765 77.5263 10.5891 76.4004 10.5891C75.1463 10.5798 73.8978 10.7575 72.6961 11.1163C72.4904 10.7859 72.3837 10.4034 72.3887 10.0143C72.3792 9.88568 72.4024 9.75678 72.4561 9.63959C72.5099 9.52241 72.5925 9.42075 72.6961 9.34411Z"
        fill={color}
      />
      <path
        d="M82.4648 9.51241C83.891 9.00988 85.3874 8.73497 86.8991 8.69778C89.8441 8.69778 90.8547 9.58389 90.8547 11.9056V20.526C90.8547 20.9103 90.7986 20.9103 88.4976 20.9103V12.4812C88.4976 10.9734 88.2179 10.3993 86.8138 10.3993C86.1007 10.4064 85.3958 10.5528 84.7388 10.8304V20.5268C84.7388 20.9111 84.682 20.9111 82.4655 20.9111L82.4648 9.51241Z"
        fill={color}
      />
      <path
        d="M112.014 20.2863C110.694 20.857 109.271 21.1503 107.833 21.1478C104.493 21.1478 103.623 19.8559 103.623 17.1738V9.34411C103.623 8.9368 103.623 8.9368 105.981 8.9368V17.3413C105.981 18.8968 106.345 19.4478 107.861 19.4478C108.513 19.4569 109.158 19.3093 109.741 19.0175V9.34411C109.741 8.9368 109.769 8.9368 112.014 8.9368V20.2863Z"
        fill={color}
      />
      <path
        d="M116.307 14.4671C117.576 14.5144 118.845 14.4265 120.095 14.205C120.199 13.7333 120.247 13.2508 120.237 12.7679C120.208 11.0441 119.844 10.3977 118.637 10.3977C117.486 10.3977 116.476 11.2354 116.307 14.4671ZM122.455 12.5527C122.474 13.5988 122.37 14.6436 122.148 15.6659C120.204 15.9306 118.244 16.059 116.282 16.0502C116.396 18.5863 117.18 19.258 118.895 19.258C120.003 19.2485 121.095 18.9953 122.094 18.5164C122.229 18.942 122.295 19.3864 122.29 19.8328C122.29 20.5752 120.523 21.1509 118.418 21.1509C115.805 21.1509 113.928 20.2163 113.928 15.5484C113.928 9.5616 116.425 8.70009 118.895 8.70009C121.251 8.70009 122.458 9.96892 122.458 12.555"
        fill={color}
      />
      <path
        d="M124.164 9.77525C125.319 9.08674 126.636 8.71558 127.981 8.69933C129.412 8.69933 129.945 9.05899 129.945 9.70455C129.942 10.1021 129.856 10.4947 129.693 10.8573C129.27 10.6654 128.811 10.5673 128.346 10.5699C127.687 10.5618 127.041 10.754 126.493 11.1209L126.466 20.5299C126.466 20.9142 126.437 20.9142 124.161 20.9142L124.164 9.77525Z"
        fill={color}
      />
      <path
        d="M135.277 15.0427C134.155 15.0427 133.202 15.3777 133.202 17.4612C133.202 19.2088 133.932 19.5201 135.027 19.5201C135.552 19.5367 136.071 19.395 136.515 19.1135C136.598 18.3235 136.598 16.5513 136.598 15.2586C136.173 15.1144 135.726 15.0414 135.277 15.0427ZM131.152 9.34411C132.519 8.85855 133.967 8.63916 135.417 8.69778C137.831 8.69778 138.897 9.46631 138.897 11.6182V12.887C138.897 15.6406 138.869 18.5133 138.729 20.3339C137.508 20.901 136.173 21.1795 134.828 21.1478C132.78 21.1708 130.815 20.7889 130.815 17.5073C130.815 14.1804 132.667 13.581 134.547 13.581C135.243 13.5626 135.937 13.668 136.595 13.8922V12.0016C136.595 10.8765 135.981 10.5891 134.855 10.5891C133.602 10.5798 132.353 10.7575 131.152 11.1163C130.946 10.7859 130.84 10.4034 130.845 10.0143C130.835 9.88567 130.858 9.75675 130.912 9.63955C130.966 9.52235 131.048 9.4207 131.152 9.34411Z"
        fill={color}
      />
      <path
        d="M97.3556 19.1719C97.1806 19.172 97.0061 19.153 96.8353 19.115C95.7187 18.8691 95.0731 17.7871 94.914 15.8873C94.8853 15.5686 94.8712 15.2274 94.8718 14.8636C94.8718 14.6331 94.8802 14.4171 94.8917 14.2065C94.8831 13.2759 95.1424 12.3624 95.6387 11.5751C95.8962 11.2449 96.2309 10.9831 96.6134 10.8128C96.9959 10.6425 97.4144 10.5688 97.8321 10.5983C98.3472 10.5981 98.8614 10.6418 99.3691 10.729V18.5525C99.3015 18.604 99.2331 18.6524 99.1624 18.6985C98.6194 19.0297 97.9912 19.1943 97.3556 19.1719ZM94.7995 24.9819C94.4239 24.9972 94.0477 24.99 93.6729 24.9604C93.5749 25.3341 93.5923 25.7288 93.7228 26.0924C93.7595 26.2231 93.8264 26.3433 93.9181 26.4433C94.0098 26.5433 94.1238 26.6203 94.2508 26.668C94.4313 26.7074 94.6149 26.7303 94.7995 26.7364C94.9163 26.7434 94.6965 26.7364 94.8372 26.7364C96.6151 26.806 98.3529 26.1972 99.6988 25.0334C100.108 24.6871 100.465 24.2838 100.76 23.8361C101.413 22.8216 101.747 21.6346 101.718 20.4284V9.28493L101.579 9.24651C100.356 8.92466 99.0955 8.76599 97.8305 8.77463C97.092 8.7157 96.3495 8.81789 95.6543 9.07411C94.9591 9.33034 94.3279 9.7345 93.8043 10.2586C92.9789 11.1939 92.5508 12.6741 92.5216 14.6507C92.5216 14.7191 92.5178 14.7852 92.5178 14.8567C92.5111 15.6008 92.5798 16.3436 92.723 17.0739C92.8115 17.7268 93.039 18.3531 93.3901 18.9107C93.7411 19.4683 94.2076 19.9441 94.758 20.3063C95.5502 20.7496 96.4483 20.9683 97.3556 20.9387C98.0416 20.9386 98.722 20.8155 99.3645 20.5752V20.6575C99.385 21.2017 99.2919 21.7443 99.091 22.2506C98.8902 22.7568 98.586 23.2157 98.1979 23.5978C98.1349 23.6585 98.0749 23.72 98.0073 23.7784C97.3559 24.4229 96.5006 24.8206 95.588 24.9035C95.1207 24.9566 94.9732 24.9735 94.7995 24.9804"
        fill={color}
      />
    </svg>
  );
};

export default SvgAnhangueraFullLogo;
