import {
  css,
  px2grid,
  px2rem,
  mediaQuery,
  ColorNeutralDark,
  ColorAccentBase,
  getColor,
} from '@ampli/ui';

export default {
  dropdownIcon: css`{dropdownItemStyle};
  margin-left: 8%;
  width: ${px2grid(12)};`,
  menuItem: (item, isInscriptionPage) => css`
    align-self: center;
    padding: ${px2grid(20)} 0;
    ${!item && isInscriptionPage && 'display: none;'}
  `,
  menuLink: css`
    width: 100%;
    background: transparent;
    border: 0;
    color: ${ColorNeutralDark};
    cursor: pointer;
    margin: ${px2grid(12)};
    text-decoration: none;

    &:active,
    &:hover,
    &.active {
      color: ${ColorAccentBase};
      text-decoration: none;
    }
    &.isFirst {
      margin-left: 0px;
    }
  `,
  menu: css`
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  `,
  nav: css`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    margin-left: ${px2rem(32)};

    ${mediaQuery('desktop')} {
      justify-content: flex-end;
    }
  `,
  alertBanner: css`
    width: 100%;
    background-color: ${getColor('anhanguera-alert-banner')};
    transition: all 0.3s ease;
    overflow: hidden;
  `,
  alertBannerOff: css`
    @keyframes moveToTop {
      from {
        height: ${px2grid(48)};
      }
      to {
        height: 0px;
      }
    }
    animation: moveToTop 0.3s ease;

    height: 0px;

    width: 100%;
    background-color: ${getColor('anhanguera-alert-banner')};
    overflow: hidden;
  `,
  alertBannerContent: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${px2grid(12)};
  `,
  alertBannerText: css`
    display: block;
    color: black;
    text-decoration: none;
    font-size: ${px2rem(16)};
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
    margin-left: auto;
  `,
  alertBannerButton: css`
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: ${px2rem(25)};
    height: ${px2rem(25)};
    background: ${getColor('anhanguera-alert-button-background')};
    box-shadow: 1px 2px 4px 1px ${getColor('anhanguera-alert-button-shadow')};
    margin-left: auto;
    &::before {
      content: ' ';
      position: relative;
      display: block;
      background-color: ${getColor('anhanguera-alert-button-icon')};
      width: ${px2rem(2)};
      height: ${px2rem(15)};
      top: 4px;
      left: 5.8px;
      transform: rotate(45deg);
      border-radius: 99px;
    }
    &::after {
      content: ' ';
      position: relative;
      display: block;
      background-color: ${getColor('anhanguera-alert-button-icon')};
      width: ${px2rem(2)};
      height: ${px2rem(15)};
      bottom: 11px;
      left: 5.8px;
      transform: rotate(-45deg);
      border-radius: 99px;
    }
  `,
};

export const contactMenuLink = (item, isInscriptionPage) =>
  item.label === 'contato' &&
  isInscriptionPage &&
  css`
    margin: 0;
  `;
